import React from 'react';
import { JourDeLaSemaine } from '../../../types';
import { joursDeLaSemaine } from '../../../utils/french';
import styles from './InfoRecurrence.module.scss';

export interface Props {
	mois?: number;
	semaine?: JourDeLaSemaine[];
}

function InfoRecurrence({ mois, semaine = [] }: Props) {
	return mois || semaine.length ? (
		<div className={styles.wrapper}>
			{mois ? (
				<>
					Le <span className={styles.selected}>{mois}</span>
					<br />
					chaque mois
				</>
			) : (
				<>
					{joursDeLaSemaine.map((j, i) => (
						<span
							key={i}
							className={[styles.day, semaine.includes(j) && styles.selected]
								.filter(Boolean)
								.join(' ')}
						>
							{j.charAt(0)}
						</span>
					))}
					<br />
					chaque semaine
				</>
			)}
		</div>
	) : null;
}

export default InfoRecurrence;
