import React from 'react';
import { ReactComponent as Avion } from './avion.svg';
import styles from './NoInternet.module.scss';

function NoInternet() {
	return (
		<div className={styles.wrapper}>
			<p className={styles.banner}>
				<Avion className={styles.picto} />
				Aucune connexion internet
			</p>
		</div>
	);
}

export default NoInternet;
