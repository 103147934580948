import { CSSProperties } from 'react';
import styles from './PositionedPopin.module.scss';

export interface Position {
	top?: number;
	left?: number;
	width?: number;
	height?: number;
}

interface GeneratedStyles {
	contentClasses: string;
	contentStyle: CSSProperties;
	cachePath: string;
}

export function generateStyles(
	{ top = 0, left = 0, width = 0, height = 0 }: Position,
	hasNoMinWidth?: boolean,
	noMarginOnMobile?: boolean,
): GeneratedStyles {
	const btnIsRight = left > window.innerWidth / 2;
	const btnIsBottom = top + height > window.innerHeight / 2;

	const contentClasses = [
		styles.contentWrapper,
		btnIsBottom && styles.bottom,
		noMarginOnMobile && styles.noMarginOnMobile,
	]
		.filter(Boolean)
		.join(' ');

	const contentStyle: CSSProperties = {
		minWidth: hasNoMinWidth ? undefined : width,
		top: top + (btnIsBottom ? 0 : height),
		left: left + (btnIsRight ? width : 0),
		transform: [
			btnIsRight && 'translateX(-100%)',
			btnIsBottom && 'translateY(-100%)',
		]
			.filter(Boolean)
			.join(' '),
	};

	const cachePath = [
		'M0 0',
		'h' + window.innerWidth,
		'v' + window.innerHeight,
		'H0',
		'z',

		`M ${left} ${top}`,
		'h' + width,
		'v' + height,
		'H' + left,
		'z',
	].join(' ');

	return { contentStyle, contentClasses, cachePath };
}
