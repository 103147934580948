import { loader } from 'graphql.macro';
import React, { ComponentProps, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import request from '../../utils/request';
import FilterByDateRange from '../FilterByDateRange/FilterByDateRange';
import OrderBy from '../OrderBy/OrderBy';
import TableHeader from '../Table/TableHeader/TableHeader';
import TablePagination from '../Table/TablePagination/TablePagination';
import TableWrapper from '../Table/TableWrapper/TableWrapper';
import ListeCommandes, { Commande } from './ListeCommandes/ListeCommandes';

const query = loader('./getCommandes.graphql');
const itemsPerPage = 10;

export interface Props extends RouteComponentProps {
	codeClient?: string;
	favorites?: boolean;
}

export function _Commandes({ location, codeClient, favorites }: Props) {
	useTitle('Mes commandes');

	const [commandes, setCommandes] = useState<Commande[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';
	const dateStart = searchParams.get('dateStart');
	const dateEnd = searchParams.get('dateEnd');

	function updateFavori(id: Commande['id'], isFavorite: boolean) {
		const newCommandes = commandes.map((c) => {
			if (c.id === id) c.favorite = !isFavorite;
			return c;
		});
		setCommandes(newCommandes);
	}

	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		setLoading(true);

		const params = {
			where: {
				client: { code: codeClient },
				...(dateStart && { dateLivraison_gte: dateStart }),
				...(dateEnd && { dateLivraison_lte: dateEnd }),
				...(favorites !== undefined && { favorite: favorites }),
			},
			skip: itemsPerPage * (pageIndex - 1),
			first: itemsPerPage,
			orderBy: `${orderBy}_${order}`,
		};

		type TResponse = { countCommandes: number; commandes: Commande[] };
		request<TResponse>(query, params)
			.then(({ commandes, countCommandes }) => {
				if (!isMounted) return;
				setCommandes(commandes);
				setTotalCount(countCommandes);
				setError('');
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [codeClient, order, orderBy, pageIndex, favorites, dateEnd, dateStart]);

	if (!orderBy || !order) {
		return <Redirect to={`${location.pathname}?orderBy=numero&order=DESC`} />;
	}

	const orderByFields: ComponentProps<typeof OrderBy>['fields'] = [
		{ name: 'Date de livraison', slug: 'dateLivraison' },
		{ name: 'Numéro', slug: 'numero' },
		{ name: 'Nom', slug: 'nom' },
		{ name: 'État', slug: 'statut' },
	];

	return (
		<TableWrapper>
			<TableHeader>
				<OrderBy fields={orderByFields} />
				<FilterByDateRange label="Date de livraison" />
			</TableHeader>

			<ListeCommandes
				error={error}
				loading={loading}
				commandes={commandes}
				setCommandeFavorite={updateFavori}
				noResultMessage="Vous n'avez aucune commande"
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { codeClient: state.compte.code };
}

export default withRouter(connect(mapStateToProps)(_Commandes));
