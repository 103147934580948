import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowSVG } from './arrow.svg';
import styles from './BtnBack.module.scss';

export interface Props {
	to?: string;
	withText?: boolean;
	className?: string;
}

function BtnBack({ to, className, withText }: Props) {
	const classNames = [styles.btn, className].filter(Boolean).join(' ');

	const content = (
		<>
			<ArrowSVG
				className={[styles.arrow, withText && styles.withText]
					.filter(Boolean)
					.join(' ')}
			/>
			{withText && 'Retour'}
		</>
	);

	return to ? (
		<Link to={to} className={classNames}>
			{content}
		</Link>
	) : (
		<span className={classNames}>{content}</span>
	);
}

export default BtnBack;
