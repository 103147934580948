import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface Props {
	children: ReactNode;
}

function PopinPortal({ children }: Props) {
	return createPortal(children, document.getElementById('popin')!);
}

export default PopinPortal;
