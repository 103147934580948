import React from 'react';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import styles from './ExportSelectedButton.module.scss';

interface Props {
	number: number;
	loading: boolean;
	onClick: () => void;
}

function ExportSelectedButton({ number, loading, onClick }: Props) {
	return (
		<Button onClick={onClick} disabled={!number} className={styles.wrapper}>
			{loading && <Loader className={styles.loader} />}
			Exporter la sélection{!!number && ` (${number})`}
		</Button>
	);
}

export default ExportSelectedButton;
