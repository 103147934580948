import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SmallLink.module.scss';

export interface Props {
	to: string;
	children: string;
}

function SmallLink({ to, children }: Props) {
	return (
		<Link to={to} className={styles.link}>
			{children}
		</Link>
	);
}

export default SmallLink;
