import { JourDeLaSemaine, StatutCommande, Unite } from '../types';

export const thinSpace = '\u202F';

export function french(string: string) {
	return (
		string
			// Remove double spaces
			.replace(/\s+/g, ' ')

			// Espaces fines entre les nombres et les unités
			.replace(/(\d)\s?([%€]|°C|kg|km|cm)/g, `$1${thinSpace}$2`)
			.replace(/(\d)\s?([gl])([.,\s])/g, `$1${thinSpace}$2$3`)
			.replace(/(\d)\s(\d)/g, `$1${thinSpace}$2`)

			// Nombres à virgule
			.replace(/(\d)\.(\d)/g, `$1,$2`)

			// Espaces entre milliers dans les nombres
			.replace(/(\d)(\d\d\d,)/g, `$1${thinSpace}$2`)

			// Non-breaking spaces before ';' ':' '!' and '?'.
			.replace(/\s?([;:!?])/g, thinSpace + '$1')

			// French guillemets with thinSpace spaces
			.replace(/"(.+?)"/g, `\u00AB${thinSpace}$1${thinSpace}\u00BB`)

			// Non-breaking spaces before and after 'h' in hours
			.replace(/(\d{1,2})\s?h\s?(\d{1,2})/g, `$1${thinSpace}h${thinSpace}$2`)
	);
}

export function convertUnite(unite: Unite, pluriel?: boolean) {
	return {
		K: 'kg',
		U: 'pièce' + (pluriel ? 's' : ''),
		C: 'colis',
	}[unite];
}

export function convertStatut(statut: StatutCommande) {
	return {
		A_EN_ATTENTE_DE_VALIDATION: 'En attente de validation',
		B_EN_COURS_DE_PREPARATION: 'En cours de préparation',
		C_EN_COURS_DE_LIVRAISON: 'En cours de livraison',
		D_LIVREE: 'Livrée',
		E_FACTUREE: 'Facturée',
	}[statut];
}

export const joursDeLaSemaine: JourDeLaSemaine[] = [
	'LUNDI',
	'MARDI',
	'MERCREDI',
	'JEUDI',
	'VENDREDI',
	'SAMEDI',
	'DIMANCHE',
];

export function truncate(text: string, length: number) {
	return text.length > length ? text.substring(0, length) + '...' : text;
}
