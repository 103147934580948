import React, { useRef } from 'react';
import styles from './SkeletonLine.module.scss';

function SkeletonLine() {
	const width = useRef(Math.random() * 100 + 20 + '%');
	return (
		<div className={styles.line}>
			<div className={styles.picto} />
			<div className={styles.text} style={{ width: width.current }} />
		</div>
	);
}

export default SkeletonLine;
