import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { AppState } from '../../../store';
import { closeFiltres } from '../../../store/filtres/actions';
import { FiltresState } from '../../../store/filtres/types';
import { Position } from '../../Popin/PositionedPopin/GenerateStyles';
import PositionedPopin from '../../Popin/PositionedPopin/PositionedPopin';
import styles from './PopinsFiltres.module.scss';

export interface Props extends FiltresState, RouteComponentProps {
	closeFiltres: typeof closeFiltres;
}

export function _PopinsFiltres({
	filtres,
	closeFiltres,
	location: { pathname, search, key },
}: Props) {
	// Close Popins on route change
	const oldURL = useRef(key);
	useEffect(() => {
		if (oldURL.current !== key) {
			closeFiltres();
		}
		oldURL.current = key;
	});

	function toFiltre(slug: string, option: string) {
		const searchParams = new URLSearchParams(search);
		searchParams.set(slug, option);
		searchParams.delete('page');
		return pathname + '?' + searchParams;
	}

	function removeFiltre(slug: string) {
		const searchParams = new URLSearchParams(search);
		searchParams.delete(slug);
		searchParams.delete('page');
		return pathname + '?' + searchParams;
	}

	function isActive(slug: string, option: string) {
		const searchParams = new URLSearchParams(search);
		return searchParams.get(slug) === option;
	}

	function isUnset(slug: string) {
		const searchParams = new URLSearchParams(search);
		return !searchParams.has(slug);
	}

	function getPosition(slug: string): Position {
		const element = document.getElementById(`bouton-open-${slug}`);
		if (element) {
			const { top, left, width } = element.getBoundingClientRect();
			return { top, left, height: 0, width };
		}
		return {};
	}

	return (
		<>
			{filtres.map((filtre, i) => (
				<PositionedPopin
					key={i}
					isOpen={filtre.isOpen}
					onClose={closeFiltres}
					position={getPosition(filtre.slug)}
					hasNoMinWidth
				>
					<h1 className={styles.title}>{filtre.nom}</h1>

					<NavLink
						to={removeFiltre(filtre.slug)}
						className={styles.link}
						activeClassName={styles.active}
						isActive={() => isUnset(filtre.slug)}
					>
						Afficher tout
					</NavLink>

					{filtre.options.map((option, u) => (
						<NavLink
							key={u}
							to={toFiltre(filtre.slug, option.slug)}
							className={styles.link}
							activeClassName={styles.active}
							isActive={() => isActive(filtre.slug, option.slug)}
						>
							{option.nom}
						</NavLink>
					))}
				</PositionedPopin>
			))}
		</>
	);
}

function mapStateToProps(state: AppState) {
	return state.filtres;
}

export default withRouter(
	connect(mapStateToProps, { closeFiltres })(_PopinsFiltres),
);
