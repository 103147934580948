import React, { ReactNode } from 'react';
import styles from './TableHeader.module.scss';

interface Props {
	children: ReactNode;
}

function TableHeader({ children }: Props) {
	return <div className={styles.wrapper}>{children}</div>;
}

export default TableHeader;
