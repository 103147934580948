import React, { ComponentProps } from 'react';
import { RowProps, TableProps } from '../types';
import Block from './Block/Block';
import styles from './TableMobile.module.scss';

type Props = {
	columns: ComponentProps<typeof Block>['columns'];
	rows: (Omit<ComponentProps<typeof Block>, 'columns'> &
		Pick<RowProps, 'id'>)[];
} & Pick<
	TableProps,
	'error' | 'loading' | 'noResultMessage' | 'selectableRows'
>;

function TableMobile({
	rows,
	error,
	columns,
	loading,
	noResultMessage,
	selectableRows,
}: Props) {
	const isSelected = (id: string) =>
		selectableRows ? selectableRows.selectedIDs.includes(id) : false;

	function toggleSelected(id: string) {
		if (isSelected(id)) {
			const filtered = selectableRows!.selectedIDs.filter((i) => i !== id);
			selectableRows!.setSelectedIDs(filtered);
		} else {
			selectableRows!.setSelectedIDs([...selectableRows!.selectedIDs, id]);
		}
	}

	return error ? (
		<p className={styles.error}>{error}</p>
	) : loading ? (
		<>
			{[...new Array(3)].map((_, i) => (
				<Block
					key={i}
					columns={columns}
					mobileTitle={
						<span className={styles.skeleton + ' ' + styles.title}>&nbsp;</span>
					}
					cells={[...new Array(columns.length)].map((_) => ({
						content: <>&nbsp;</>,
						className: styles.skeleton,
					}))}
				/>
			))}
		</>
	) : !rows.length ? (
		<p className={styles.noResult}>{noResultMessage}</p>
	) : (
		<>
			{rows.map((row) => {
				const { id, ...props } = row;
				return (
					<Block
						key={id}
						{...props}
						columns={columns}
						select={
							selectableRows && {
								selected: isSelected(row.id),
								toggle: () => toggleSelected(row.id),
							}
						}
					/>
				);
			})}
		</>
	);
}

export default TableMobile;
