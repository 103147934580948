import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Button/Button';
import { ReactComponent as CheckSVG } from './check.svg';
import styles from './DoneMessage.module.scss';

export interface Props {
	children: string;
	link: {
		to: string;
		text: string;
	};
}

function DoneMessage({ children, link: { to, text } }: Props) {
	return (
		<div className={styles.done}>
			<CheckSVG className={styles.svg} />

			<p className={styles.message}>{children}</p>

			<Link to={to}>
				<Button>{text}</Button>
			</Link>
		</div>
	);
}

export default DoneMessage;
