import React, { useEffect, useRef, useState } from 'react';
import styles from './ComCommande.module.scss';
import { ReactComponent as CrayonSVG } from './crayon.svg';

export interface Props {
	com?: string;
	loading?: boolean;
	className?: string;
	changeCom: (val: string) => void;
}

function ComCommande({ com, changeCom, loading, className }: Props) {
	const [newCom, setNewCom] = useState('');
	const input = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setNewCom(com || '');
	}, [com]);

	return (
		<label
			title="Éditer Votre référence de commande "
			className={[styles.wrapper, className, loading && styles.loading]
				.filter(Boolean)
				.join(' ')}
		>
			<span className={styles.picto}>
				<CrayonSVG />
			</span>

			<input
				ref={input}
				type="text"
				value={newCom}
				disabled={loading}
				className={styles.input}
				placeholder={'Votre référence de commande '}
				//
				onBlur={() => changeCom(newCom)}
				onChange={(c) => setNewCom(c.target.value)}
				onKeyDown={({ key }) => key === 'Enter' && input.current!.blur()}
			/>
		</label>
	);
}

export default ComCommande;
