import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { getFiltres } from '../../store/filtres/actions';
import { FiltresState } from '../../store/filtres/types';
import Filtre from './Filtre/Filtre';
import styles from './Filtres.module.scss';
import PopinsFiltres from './PopinsFiltres/PopinsFiltres';

export interface Props extends FiltresState {
	getFiltres: typeof getFiltres;
}

export function _Filtres({ filtres, loading, error, getFiltres }: Props) {
	useEffect(() => {
		if (!filtres.length) getFiltres();
	}, [filtres, getFiltres]);

	return (
		<div className={styles.wrapper}>
			{/* Loading */}
			{loading && (
				<>
					<div className={styles.skeleton} />
					<div className={styles.skeleton} />
					<div className={styles.skeleton} />
				</>
			)}

			{/* Error */}
			{error && <p className={styles.error}>{error}</p>}

			{/* Filtres */}
			{filtres.map((filtre, i) => (
				<Filtre key={i} {...filtre} />
			))}

			{/* Popins */}
			<PopinsFiltres />
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return state.filtres;
}

export default connect(mapStateToProps, { getFiltres })(_Filtres);
