import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage
} from '../../../utils/localStorage';
import { CHANGE_COM_PANIER, PanierActionTypes } from '../types';

export function changeComPanier(com: string): PanierActionTypes {
	let panier = loadStateFromLocalStorage('panier');
	if (panier) saveStateToLocalStorage('panier', { ...panier, com });
	return { type: CHANGE_COM_PANIER, com };
}
