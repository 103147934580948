import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import {
	changeComPanier,
	changeDateLivraisonPanier,
	changeNomPanier,
	emptyPanier,
	getInfoProduitsPanier,
	removePanierRecurrence,
	setPanierAsCommandeFavorite,
	setPanierRecurrence,
} from '../../../store/panier/actions';
import { PanierState } from '../../../store/panier/types';
import { thinSpace } from '../../../utils/french';
import BtnFavori from '../../BtnFavori/BtnFavori';
import BtnPoubelle from '../../BtnPoubelle/BtnPoubelle';
import ComCommande from '../../ComCommande/ComCommande';
import DatePicker from '../../DatePicker/DatePicker';
import NomCommande from '../../NomCommande/NomCommande';
import Recurrence from '../../Recurrence/Recurrence';
import styles from './Header.module.scss';

export interface Props {
	nom?: PanierState['nom'];
	com?: PanierState['com'];
	favorite: PanierState['favorite'];
	lignesCount: number;
	dateLivraison?: PanierState['dateLivraison'];
	recurrenceMois?: PanierState['recurrenceMois'];
	recurrenceSemaine?: PanierState['recurrenceSemaine'];

	emptyPanier: typeof emptyPanier;
	changeNomPanier: typeof changeNomPanier;
	changeComPanier: typeof changeComPanier;
	setPanierRecurrence: typeof setPanierRecurrence;
	getInfoProduitsPanier: typeof getInfoProduitsPanier;
	removePanierRecurrence: typeof removePanierRecurrence;
	changeDateLivraisonPanier: typeof changeDateLivraisonPanier;
	setPanierAsCommandeFavorite: typeof setPanierAsCommandeFavorite;
}

export function _Header({
	// State
	nom,
	com,
	favorite,
	lignesCount,
	dateLivraison,
	recurrenceMois,
	recurrenceSemaine,

	// Actions
	emptyPanier,
	changeNomPanier,
	changeComPanier,
	setPanierRecurrence,
	getInfoProduitsPanier,
	removePanierRecurrence,
	changeDateLivraisonPanier,
	setPanierAsCommandeFavorite,
}: Props) {
	const today = new Date();
	const dateIn3Months = new Date();
	dateIn3Months.setMonth(dateIn3Months.getMonth() + 3);

	if (today.getHours() > 2 && today.getMinutes() > 0) {
		today.setDate(today.getDate() + 1);
	}

	return (
		<>
			<div className={styles.line}>
				<div className={styles.lineCommande}>
					<NomCommande
						nom={nom}
						changeNom={changeNomPanier}
						className={styles.nom}
					/>

					<ComCommande
						com={com}
						changeCom={changeComPanier}
						className={styles.nom}
					/>
				</div>

				<BtnFavori
					checked={favorite}
					onClick={() => setPanierAsCommandeFavorite(!favorite)}
					title={
						favorite
							? 'Enlever la commande des favoris'
							: 'Ajouter la commande aux favoris'
					}
				/>

				<BtnPoubelle title="Vider le panier" onClick={emptyPanier} />
			</div>

			<div className={[styles.line, styles.last].join(' ')}>
				<DatePicker
					required
					selected={dateLivraison}
					minDate={today}
					maxDate={dateIn3Months}
					className={styles.livraison}
					label={<strong>Date de livraison{thinSpace}:</strong>}
					onChange={(date) => {
						changeDateLivraisonPanier(date!);
						if (lignesCount) getInfoProduitsPanier();
					}}
				/>

				<Recurrence
					className={styles.recurrence}
					recurrenceMois={recurrenceMois}
					recurrenceSemaine={recurrenceSemaine}
					setRecurrence={({ mois, semaine }) => {
						if (mois) setPanierRecurrence({ mois });
						else if (semaine) setPanierRecurrence({ semaine });
						else removePanierRecurrence();
					}}
				/>
			</div>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		nom: state.panier.nom,
		favorite: state.panier.favorite,
		lignesCount: state.panier.lignes.length,
		dateLivraison: state.panier.dateLivraison,
		recurrenceMois: state.panier.recurrenceMois,
		recurrenceSemaine: state.panier.recurrenceSemaine,
	};
}

export default connect(mapStateToProps, {
	emptyPanier,
	changeNomPanier,
	changeComPanier,
	setPanierRecurrence,
	getInfoProduitsPanier,
	removePanierRecurrence,
	changeDateLivraisonPanier,
	setPanierAsCommandeFavorite,
})(_Header);
