import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Checkbox from '../../../Checkbox/Checkbox';
import { ColumnProps, RowProps } from '../../types';
import Td from '../Td/Td';
import styles from './Tr.module.scss';

type Props = RouteComponentProps &
	Pick<RowProps, 'cells' | 'href'> & {
		columns: Pick<ColumnProps, 'center'>[];
		select?: { selected: boolean; toggle: () => void };
	};

export function _Tr({ href, cells, columns, history, select }: Props) {
	return (
		<tr
			onClick={() => href && history.push(href)}
			className={[styles.wrapper, href && styles.clickable]
				.filter(Boolean)
				.join(' ')}
		>
			{/* Select id */}
			{select && (
				<Td
					stopPropagation
					content={
						<Checkbox
							onClick={select.toggle}
							checked={select.selected}
							className={styles.checkbox}
						/>
					}
				/>
			)}

			{/* Cells */}
			{cells.map((cell, i) => (
				<Td key={i} center={columns[i].center} {...cell} />
			))}
		</tr>
	);
}

export default withRouter(_Tr);
