import React from 'react';
import styles from './Label.module.scss';

export interface Props {
	children: string;
}

function Label({ children }: Props) {
	return <span className={styles.label}>{children}</span>;
}

export default Label;
