import React, { ComponentProps } from 'react';
import Pagination from '../../Pagination/Pagination';
import styles from './TablePagination.module.scss';

type Props = Pick<
	ComponentProps<typeof Pagination>,
	'itemsCountPerPage' | 'loading' | 'totalItemsCount'
> & { error: boolean };

function TablePagination({
	error,
	loading,
	totalItemsCount,
	itemsCountPerPage,
}: Props) {
	return error || !totalItemsCount ? null : (
		<Pagination
			showCount
			loading={loading}
			className={styles.wrapper}
			totalItemsCount={totalItemsCount}
			itemsCountPerPage={itemsCountPerPage}
		/>
	);
}

export default TablePagination;
