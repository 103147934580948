import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	AlertesActionTypes,
	AlertesThunkResult,
	AlerteType,
	CLOSE_ALERTES,
	DELETE_ALERTE_FAIL,
	DELETE_ALERTE_START,
	DELETE_ALERTE_SUCCESS,
	DELETE_ALL_ALERTES_FAIL,
	DELETE_ALL_ALERTES_START,
	DELETE_ALL_ALERTES_SUCCESS,
	GET_ALERTES_FAIL,
	GET_ALERTES_START,
	GET_ALERTES_SUCCESS,
	OPEN_ALERTES,
	READ_ALERTE_START,
} from './types';

const getAlertesQuery = loader('./getAlertes.graphql');
const readAlerteMutation = loader('./readAlerte.graphql');
const deleteAlerteMutation = loader('./deleteAlerte.graphql');
const deleteAllAlertesMutation = loader('./deleteAllAlertes.graphql');

// Open / Close
export function openAlertes(): AlertesActionTypes {
	return { type: OPEN_ALERTES };
}
export function closeAlertes(): AlertesActionTypes {
	return { type: CLOSE_ALERTES };
}

// Get Alertes
export function getAlertes(): AlertesThunkResult {
	return async function (dispatch) {
		dispatch({ type: GET_ALERTES_START });
		try {
			type TResponse = { list: AlerteType[] };
			const res = await request<TResponse>(getAlertesQuery);
			dispatch({ type: GET_ALERTES_SUCCESS, list: res.list });
		} catch (err) {
			dispatch({ type: GET_ALERTES_FAIL, error: err.message });
		}
	};
}

// Read one Alerte
export function readAlerte(id: string): AlertesThunkResult {
	return async function (dispatch) {
		dispatch({ type: READ_ALERTE_START, id });
		await request(readAlerteMutation, { id }).catch();
	};
}

// Delete one alerte
export function deleteAlerte(id: string): AlertesThunkResult {
	return async function (dispatch) {
		dispatch({ type: DELETE_ALERTE_START, id });
		try {
			await request(deleteAlerteMutation, { id });
			dispatch({ type: DELETE_ALERTE_SUCCESS, id });
		} catch (err) {
			dispatch({ type: DELETE_ALERTE_FAIL, id, error: err.message });
		}
	};
}

// Delete all alertes
export function deleteAllAlertes(): AlertesThunkResult {
	return async function (dispatch) {
		dispatch({ type: DELETE_ALL_ALERTES_START });
		try {
			await request(deleteAllAlertesMutation);
			dispatch({ type: DELETE_ALL_ALERTES_SUCCESS });
		} catch (err) {
			dispatch({ type: DELETE_ALL_ALERTES_FAIL, error: err.message });
		}
	};
}
