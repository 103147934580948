import React, { ComponentProps } from 'react';
import { RowProps, TableProps } from '../types';
import styles from './TableDesktop.module.scss';
import Thead from './Thead/Thead';
import Tr from './Tr/Tr';

type Props = {
	rows: (Omit<
		ComponentProps<typeof Tr>,
		'columns' | 'selected' | 'toggleSelection'
	> &
		Pick<RowProps, 'id'>)[];
	columns: ComponentProps<typeof Thead>['columns'];
} & Pick<
	TableProps,
	'error' | 'loading' | 'noResultMessage' | 'selectableRows'
>;

function TableDesktop({
	rows,
	error,
	columns,
	loading,
	selectableRows,
	noResultMessage,
}: Props) {
	const isSelected = (id: string) =>
		selectableRows ? selectableRows.selectedIDs.includes(id) : false;

	const isPageSelected =
		!!rows.length && selectableRows
			? rows.every((row) => selectableRows.selectedIDs.includes(row.id))
			: false;

	const isPagePartlySelected =
		!!rows.length && selectableRows
			? rows.some((row) => selectableRows.selectedIDs.includes(row.id))
			: false;

	function toggleSelected(id: string) {
		if (!selectableRows) return;

		if (isSelected(id)) {
			const filtered = selectableRows.selectedIDs.filter((i) => i !== id);
			selectableRows.setSelectedIDs(filtered);
		} else {
			selectableRows.setSelectedIDs([...selectableRows.selectedIDs, id]);
		}
	}

	function togglePageSelection() {
		if (!selectableRows) return;

		if (!isPageSelected && !isPagePartlySelected) {
			selectableRows.setSelectedIDs([
				...selectableRows.selectedIDs,
				...rows.map((r) => r.id),
			]);
		} else {
			const filtered = selectableRows.selectedIDs.filter(
				(id) => !rows.find((r) => r.id === id),
			);
			selectableRows.setSelectedIDs(filtered);
		}
	}

	return (
		<table className={styles.wrapper}>
			<Thead
				columns={columns}
				select={
					selectableRows && {
						isPageSelected,
						isPagePartlySelected,
						toggle: togglePageSelection,
					}
				}
			/>

			<tbody>
				{error ? (
					// Error
					<tr>
						<td
							colSpan={100}
							className={[styles.zeroResult, styles.error].join(' ')}
						>
							{error}
						</td>
					</tr>
				) : loading ? (
					// Loading
					[...new Array(10)].map((_, i) => (
						<Tr
							key={i}
							columns={columns}
							cells={[...new Array(columns.length)].map((_, i) => ({
								content: <span className={styles.skeleton}>&nbsp;</span>,
							}))}
						/>
					))
				) : !rows.length ? (
					// No result
					<tr>
						<td colSpan={100} className={styles.zeroResult}>
							{noResultMessage}
						</td>
					</tr>
				) : (
					// Results
					rows.map((row, i) => {
						const { id, ...props } = row;
						return (
							<Tr
								key={i}
								{...props}
								columns={columns}
								select={
									selectableRows && {
										selected: isSelected(row.id),
										toggle: () => toggleSelected(row.id),
									}
								}
							/>
						);
					})
				)}

				<tr className={styles.lastTr}>
					<td />
				</tr>
			</tbody>
		</table>
	);
}

export default TableDesktop;
