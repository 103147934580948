import React, { ReactNode, useEffect, useRef } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { isAndroid } from '../../../utils/userAgent';
import PopinPortal from '../PopinPortal';
import { generateStyles, Position } from './GenerateStyles';
import styles from './PositionedPopin.module.scss';

export interface Props {
	/** Defines when to show the Popin */
	isOpen: boolean;
	children: ReactNode;
	/** Called aat click on cache and at window resize */
	onClose: Function;
	/** The boundingClientRect of the not hidden element */
	position?: Position;
	/** Defines if the popin should have the button width as min-width */
	hasNoMinWidth?: boolean;
	/** Defines if the popin should have a margin on mobile */
	noMarginOnMobile?: boolean;
}

function PositionedPopin({
	isOpen,
	children,
	onClose,
	position = {},
	hasNoMinWidth,
	noMarginOnMobile,
}: Props) {
	// Close Popin on resize
	const isOpenRef = useRef(isOpen);
	const winW = useRef(window.innerWidth);

	useEffect(() => {
		isOpenRef.current = isOpen;
	});

	useEffect(() => {
		function onResize() {
			if (
				isOpenRef.current &&
				(isAndroid ? winW.current !== window.innerWidth : true)
				// input focus makes the keyboard appear and it triggers a window.resize,
				// so we need to check on Android if only the window width changed
			) {
				onClose();
			}
			winW.current = window.innerWidth;
		}

		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, [onClose]);

	// Generate the style from the coordinates
	const { cachePath, contentStyle, contentClasses } = generateStyles(
		position,
		hasNoMinWidth,
		noMarginOnMobile,
	);

	return (
		<PopinPortal>
			<CSSTransition
				in={isOpen}
				timeout={+styles.duration}
				mountOnEnter
				unmountOnExit
				classNames={styles}
			>
				<div>
					{/* CACHE */}
					<svg className={styles.cache} onClick={() => onClose()}>
						<path fillRule="evenodd" d={cachePath} />
					</svg>

					{/* CONTENT */}
					<div style={contentStyle} className={contentClasses}>
						<div className={styles.hider}>
							<div className={styles.content}>{children}</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		</PopinPortal>
	);
}

export default PositionedPopin;
