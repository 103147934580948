import React from 'react';
// Redux
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { AppState } from '../../store';
import { CategoriesState } from '../../store/categories/types';
import { SelectionsState } from '../../store/selections/types';
import styles from './SideMenu.module.scss';
import SkeletonLine from './SkeletonLine/SkeletonLine';

export interface Props extends RouteComponentProps {
	selections: SelectionsState;
	categories: CategoriesState;
}

export function _SideMenu({ location, selections, categories }: Props) {
	const searchParams = new URLSearchParams(location.search);

	const activeSelectionSlug = searchParams.get('selection');
	const activeCategorieSlug = searchParams.get('categorie');

	return (
		<div className={styles.wrapper}>
			{/* SELECTIONS */}
			<h1 className={styles.title}>Nos Sélections</h1>
			{selections.error && <p className={styles.error}>{selections.error}</p>}
			{selections.loading ? (
				<>
					<SkeletonLine />
					<SkeletonLine />
					<SkeletonLine />
				</>
			) : (
				selections.list.map(({ nom, slug, pictoSrc }) => (
					<NavLink
						key={slug}
						to={`/recherche?selection=${slug}`}
						className={styles.link}
						activeClassName={styles.active}
						isActive={() => slug === activeSelectionSlug}
					>
						{pictoSrc && <ReactSVG src={pictoSrc} className={styles.picto} />}
						{nom}
					</NavLink>
				))
			)}

			{/* CATEGORIES */}
			<h1 className={styles.title}>Les Catégories</h1>
			{categories.error && <p className={styles.error}>{categories.error}</p>}
			{categories.loading ? (
				<>
					<SkeletonLine />
					<SkeletonLine />
					<SkeletonLine />
					<SkeletonLine />
				</>
			) : (
				categories.list.map(({ nom, pictoSrc, slug }) => (
					<NavLink
						key={slug}
						to={`/recherche?categorie=${slug}`}
						className={styles.link}
						activeClassName={styles.active}
						isActive={() => slug === activeCategorieSlug}
					>
						{pictoSrc && <ReactSVG src={pictoSrc} className={styles.picto} />}
						{nom}
					</NavLink>
				))
			)}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return {
		selections: state.selections,
		categories: state.categories,
	};
}

export default withRouter(connect(mapStateToProps)(_SideMenu));
