import React, { FormEvent, ReactNode } from 'react';
import styles from './Form.module.scss';

export interface Props {
	onSubmit: Function;
	children: ReactNode;
	className?: string;
}

export function Form({ onSubmit, children, className }: Props) {
	function handleSubmit(e: FormEvent) {
		e.preventDefault();
		onSubmit();
	}

	return (
		<form
			className={[styles.form, className].filter(Boolean).join(' ')}
			onSubmit={handleSubmit}
		>
			{children}
		</form>
	);
}

export default Form;
