import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { getActualites } from '../../store/actualites/actions';
import { Actualite } from '../../store/actualites/types';
import { media } from '../../utils/breakpoints/breakpoints';
import { french } from '../../utils/french';
import styles from './Actualites.module.scss';
import ActualiteSingle from './ActualiteSingle/ActualiteSingle';

export interface Props {
	error: string | null;
	list: Actualite[];
	getActualites: typeof getActualites;
}

export function _Actualites({ error, list, getActualites }: Props) {
	useEffect(() => {
		getActualites();
	}, [getActualites]);

	useTitle('Actualités');

	const actualites: Actualite[] = list.length
		? list
		: [
				{ id: '', titre: '', contenu: '', imageSrc: '', url: '' },
				{ id: '', titre: '', contenu: '', imageSrc: '', url: '' },
				{ id: '', titre: '', contenu: '', imageSrc: '', url: '' },
		  ];

	if (error) return <p className={styles.error}>{error}</p>;

	return (
		<div className={styles.wrapper}>
			<MediaQuery query={media['<laptop']}>
				<h1 className={styles.title}>{french(`À ne pas manquer !`)}</h1>
			</MediaQuery>

			{actualites.map((actu, i) => (
				<ActualiteSingle key={i} {...actu} className={styles.single} />
			))}

			{/* Fake pour le flex */}
			<div className={styles.single} />
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return {
		error: state.actualites.error,
		list: state.actualites.list,
	};
}

export default connect(mapStateToProps, { getActualites })(_Actualites);
