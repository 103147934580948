import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	GET_SELECTIONS_FAIL,
	GET_SELECTIONS_START,
	GET_SELECTIONS_SUCCESS,
	Selection,
	SelectionsThunkResult,
} from './types';

const query = loader('./getSelections.graphql');

export function getSelections(): SelectionsThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_SELECTIONS_START });
			type TResponse = { list: Selection[] };
			const { list } = await request<TResponse>(query);
			dispatch({ type: GET_SELECTIONS_SUCCESS, list });
		} catch (err) {
			dispatch({ type: GET_SELECTIONS_FAIL, error: err.message });
		}
	};
}
