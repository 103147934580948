import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Media from 'react-responsive';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from '../../store';
import { getCategories } from '../../store/categories/actions';
import { getInfoProduitsPanier } from '../../store/panier/actions';
import { getSelections } from '../../store/selections/actions';
import { media } from '../../utils/breakpoints/breakpoints';
import BtnNotifications from '../BtnNotifications/BtnNotifications';
import Footer from '../Footer/Footer';
import Gradient from '../Gradient/Gradient';
import Header from '../Header/Header';
import Panier from '../Panier/Panier';
import SideMenu from '../SideMenu/SideMenu';
import styles from './MainLayout.module.scss';
import MainRoutes from './MainRoutes/MainRoutes';

export interface Props extends RouteComponentProps {
	codeClient?: string;
	isPanierOpen: boolean;
	getSelections: typeof getSelections;
	getCategories: typeof getCategories;
	getInfoProduitsPanier: typeof getInfoProduitsPanier;
}

export function _MainLayout({
	location,
	isPanierOpen,
	getSelections,
	getCategories,
	getInfoProduitsPanier,
}: Props) {
	useEffect(() => {
		getSelections();
		getCategories();
		getInfoProduitsPanier();
	}, [getSelections, getCategories, getInfoProduitsPanier]);

	const isRechercheRoute =
		location.pathname === '/recherche' && !location.search;

	return (
		<div className={styles.wrapper}>
			{/* Header */}
			<Header />

			<div className={styles.middle}>
				{/* Mobile */}
				<Media query={media['<laptop']}>
					{isRechercheRoute ? (
						<div className={styles.sideMenuWrapper}>
							<Gradient />
							<SideMenu />
						</div>
					) : (
						<MainRoutes />
					)}
				</Media>

				{/* Desktop */}
				<Media query={media['>=laptop']}>
					<SideMenu />
					<MainRoutes />
					{isPanierOpen && <Panier />}
				</Media>
			</div>

			{/* Footer */}
			<Media query={media['<laptop']}>
				<Gradient reversed />
				<Footer />
			</Media>

			<BtnNotifications />
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return { isPanierOpen: state.panier.isOpen };
}

export default withRouter(
	connect(mapStateToProps, {
		getCategories,
		getSelections,
		getInfoProduitsPanier,
	})(_MainLayout),
);
