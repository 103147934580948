import { DocumentNode } from 'graphql';
import { useEffect, useRef } from 'react';

const interval = 5000;

const { hostname, port, protocol } = document.location;
const wsProtocol = protocol === 'http:' ? 'ws' : 'wss';
const endpoint = `${wsProtocol}://${hostname}:${port}/api`;

interface Props {
	query: DocumentNode;
	variables: { [key: string]: any };
	callback: () => void;
}

export function useWebSocket(props: Props) {
	const refs = useRef(props);

	useEffect(() => {
		let timerID = 0;
		let started = false;

		const { callback, query, variables } = refs.current;

		const subscriptionPayload = JSON.stringify({
			type: 'start',
			payload: { variables, query },
		});

		function subscribe() {
			started = true;

			let socket = new WebSocket(endpoint, 'graphql-ws');

			socket.onopen = function () {
				if (timerID) {
					clearInterval(timerID);
					timerID = 0;
					callback();
				}
				socket.send(JSON.stringify({ type: 'connection_init' }));
			};

			socket.onmessage = function (event) {
				try {
					type TResponse = { type: 'connection_ack' | 'data' };
					const { type } = JSON.parse(event.data) as TResponse;
					if (type === 'connection_ack') socket.send(subscriptionPayload);
					else if (type === 'data') callback();
				} catch (err) {
					console.error(err);
				}
			};

			socket.onclose = function (event) {
				started = false;
				if (event.wasClean) return;
				const reconnect = () => !started && (socket = subscribe());
				timerID = setInterval(reconnect, interval) as any;
			};

			return socket;
		}

		let socket = subscribe();

		return () => {
			if (socket.OPEN) socket.close();
		};
	}, []);
}
