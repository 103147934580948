import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { logout } from '../../../store/compte/actions';
import { media } from '../../../utils/breakpoints/breakpoints';
import { toggleNotifications } from '../../../utils/notifications.ts/toggleNotifications';
import { telephone } from '../../../utils/telephone';
import Popin from '../../Popin/PopinPortal';
import PositionedPopin from '../../Popin/PositionedPopin/PositionedPopin';
import styles from './PopinMenu.module.scss';

export const links = [
	{ title: 'Accueil', to: '/' },
	{ title: 'Mes commandes', to: '/commandes' },
	{ title: 'Mes commandes favorites', to: '/commandes-favorites' },
	{ title: 'Mes produits favoris', to: '/produits-favoris' },
	{ title: 'Mes factures', to: '/factures' },
	{ title: 'Mes avoirs', to: '/avoirs' },
	// { title: 'Mes bons de livraison', to: '/bonslivraison' },
	{ title: 'Contact', to: '/contact' },
	{ title: 'Mentions légales', to: '/mentions-legales' },
];

export interface Props {
	id: string;
	isOpen: boolean;
	logout: typeof logout;
	closeMenu: () => void;
}

export function List({ isOpen, closeMenu, logout }: Props) {
	return (
		<>
			{links.map(({ title, to }, i) => (
				<NavLink
					key={i}
					to={to}
					exact
					className={styles.link}
					activeClassName={styles.current}
					onClick={closeMenu}
					style={{ transitionDelay: isOpen ? i * 30 + 'ms' : '0ms' }}
				>
					{title}
				</NavLink>
			))}

			<span
				className={styles.link}
				style={{ transitionDelay: isOpen ? links.length * 30 + 'ms' : '0ms' }}
				onClick={() => {
					logout();
					closeMenu();
					toggleNotifications({ disabled: true });
				}}
			>
				Se déconnecter
			</span>

			<MediaQuery query={media['<laptop']}>
				<a
					href={`tel:${telephone}`}
					className={styles.link}
					style={{
						transitionDelay: isOpen ? (links.length + 1) * 30 + 'ms' : '0ms',
					}}
				>
					Appeler la télévente
				</a>
			</MediaQuery>
		</>
	);
}

export function _PopinMenu(props: Props) {
	const openRef = useRef(props.isOpen);

	const { closeMenu } = props;

	useEffect(() => {
		const resize = () => openRef.current && closeMenu();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, [closeMenu]);

	useEffect(() => {
		openRef.current = props.isOpen;
	});

	const position = useRef({} as ClientRect);
	const btn = document.getElementById(props.id);
	if (btn) position.current = btn.getBoundingClientRect();

	const { width, height } = position.current;

	return (
		<>
			<MediaQuery query={media['<laptop']}>
				<Popin>
					<CSSTransition
						in={props.isOpen}
						timeout={+styles.duration}
						classNames={styles}
						mountOnEnter
						unmountOnExit
					>
						<div className={styles.fullScreen}>
							<div className={styles.cache} onClick={props.closeMenu} />
							<div
								className={styles.close}
								onClick={props.closeMenu}
								style={{ width, height }}
							/>
							<List {...props} />
						</div>
					</CSSTransition>
				</Popin>
			</MediaQuery>

			<MediaQuery query={media['>=laptop']}>
				<PositionedPopin
					isOpen={props.isOpen}
					onClose={props.closeMenu}
					position={position.current}
				>
					<List {...props} />
				</PositionedPopin>
			</MediaQuery>
		</>
	);
}

export default connect(null, { logout })(_PopinMenu);
