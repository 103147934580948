import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage,
} from '../../../utils/localStorage';
import { PanierActionTypes, SET_PANIER_AS_COMMANDE_FAVORITE } from '../types';

export function setPanierAsCommandeFavorite(
	favorite: boolean,
): PanierActionTypes {
	let panier = loadStateFromLocalStorage('panier');
	if (panier) saveStateToLocalStorage('panier', { ...panier, favorite });
	return { type: SET_PANIER_AS_COMMANDE_FAVORITE, favorite };
}
