import React from 'react';
import styles from './Gradient.module.scss';

export interface Props {
	reversed?: boolean;
}

export function Gradient({ reversed }: Props) {
	return (
		<div
			className={[styles.gradient, reversed && styles.reversed]
				.filter(Boolean)
				.join(' ')}
		/>
	);
}

export default Gradient;
