import React from 'react';
import Checkbox from '../../../Checkbox/Checkbox';
import { ColumnProps } from '../../types';
import styles from './Thead.module.scss';

type Props = {
	columns: Pick<ColumnProps, 'content' | 'center'>[];
	select?: {
		toggle: () => void;
		isPageSelected: boolean;
		isPagePartlySelected: boolean;
	};
};

function Thead({ columns, select }: Props) {
	return (
		<thead>
			<tr>
				{/* Checkbox pour sélectionner toute la page */}
				{select && (
					<th className={styles.th}>
						<Checkbox
							onClick={select.toggle}
							className={styles.checkbox}
							checked={select.isPageSelected}
							halfChecked={select.isPagePartlySelected}
						/>
					</th>
				)}

				{/* Liste des colonnes */}
				{columns.map(({ content, center }) => (
					<th
						key={content}
						className={[styles.th, center && styles.centered]
							.filter(Boolean)
							.join(' ')}
					>
						{content}
					</th>
				))}
			</tr>
		</thead>
	);
}

export default Thead;
