import { Unite } from '../../../types';
import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage,
} from '../../../utils/localStorage';
import {
	ADD_PRODUIT_TO_PANIER,
	CHANGE_LIGNE_PANIER,
	EMPTY_PANIER,
	LignePanier,
	PanierActionTypes,
	REMOVE_PRODUIT_FROM_PANIER,
} from '../types';

// Add to Panier
export function addProduitToPanier(ligne: LignePanier): PanierActionTypes {
	let localPanier = loadStateFromLocalStorage('panier');
	const { code, prixUnite, quantite, uc, commentaire } = ligne;
	if (localPanier) {
		if (!localPanier.lignes.find((l) => l.code === ligne.code)) {
			localPanier.lignes.push({ code, prixUnite, quantite, uc, commentaire });
		}
	} else {
		localPanier = {
			favorite: false,
			lignes: [{ code, prixUnite, quantite, uc, commentaire }],
		};
	}
	saveStateToLocalStorage('panier', localPanier);
	return { type: ADD_PRODUIT_TO_PANIER, ligne };
}

// Change quantity
export function changeLignePanier(
	codeProduit: string,
	quantite: number,
	uc: Unite,
	commentaire?: string,
): PanierActionTypes {
	let localPanier = loadStateFromLocalStorage('panier');
	if (localPanier) {
		const lignes = localPanier.lignes.map((l) => {
			if (l.code === codeProduit) {
				l.quantite = quantite;
				l.uc = uc;
				l.commentaire = commentaire;
			}
			return l;
		});
		saveStateToLocalStorage('panier', { ...localPanier, lignes });
	}
	return { type: CHANGE_LIGNE_PANIER, codeProduit, quantite, uc, commentaire };
}

// Remove from Panier
export function removeProduitFromPanier(
	codeProduit: string,
): PanierActionTypes {
	let localPanier = loadStateFromLocalStorage('panier');
	if (localPanier) {
		const lignes = localPanier.lignes.filter((l) => l.code !== codeProduit);
		saveStateToLocalStorage('panier', { ...localPanier, lignes });
	}
	return { type: REMOVE_PRODUIT_FROM_PANIER, codeProduit };
}

// Remove all produits
export function emptyPanier(): PanierActionTypes {
	let localPanier = loadStateFromLocalStorage('panier');
	if (localPanier) {
		saveStateToLocalStorage('panier', { ...localPanier, lignes: [] });
	}
	return { type: EMPTY_PANIER };
}
