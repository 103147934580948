import React from 'react';
import styles from './Loader.module.scss';

interface Props {
	className?: string;
}

function Loader({ className }: Props) {
	return (
		<div className={[styles.loader, className].filter(Boolean).join(' ')} />
	);
}

export default Loader;
