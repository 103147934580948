import formatDate from 'dateformat';
import { loader } from 'graphql.macro';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { JourDeLaSemaine, StatutCommande } from '../../../types';
import { convertStatut, truncate } from '../../../utils/french';
import request from '../../../utils/request';
import BtnFavori from '../../BtnFavori/BtnFavori';
import InfoRecurrence from '../../Recurrence/InfoRecurrence/InfoRecurrence';
import Table from '../../Table/Table';
import { RowProps } from '../../Table/types';
import styles from './ListeCommandes.module.scss';

const setFavoriMutation = loader('./setFavori.graphql');

export interface Commande {
	id: string;
	nom?: string;
	com?: string;
	heureCommande?: string;
	statut: StatutCommande;
	numero: number;
	dateLivraison: Date;
	lignes: { produit: { nom: string } }[];
	recurrenceMois?: { date: number };
	recurrenceSemaine?: { jour: JourDeLaSemaine }[];
	favorite: boolean;
}

export interface Props extends RouteComponentProps {
	error?: string;
	loading: boolean;
	commandes: Commande[];
	noResultMessage: string;
	setCommandeFavorite: (id: Commande['id'], isFavorite: boolean) => void;
}

export function _ListeCommandes({
	error,
	loading,
	commandes,
	noResultMessage,
	setCommandeFavorite,
	location: { pathname },
}: Props) {
	function toggleCommandeFavorite(id: string, isFavorite: boolean) {
		setCommandeFavorite(id, isFavorite);
		request(setFavoriMutation, { id, favorite: !isFavorite });
	}

	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage={noResultMessage}
			columns={[
				{ content: 'Date de livraison' },
				{ content: 'Heure de commande' },
				{ content: 'Numéro', mobileHidden: true },
				{ content: 'Nom' },
				{ content: 'Commentaire' },
				{ content: 'Récurrence', center: true },
				{ content: 'Produits' },
				{ content: 'État' },
				{ content: 'Favori', center: true, isButton: true },
			]}
			rows={commandes.map((c) => {
				const mois = c.recurrenceMois;
				const semaine = c.recurrenceSemaine;

				return {
					id: c.id,
					href: `/commande/${c.id}?from=${pathname}`,
					mobileTitle: <p>Commande n°{c.numero}</p>,
					cells: [
						{ content: formatDate(c.dateLivraison, 'dd/mm/yyyy') },
						{ content: c.heureCommande },
						{ content: `N°${c.numero}`, className: styles.numero },
						{ content: c.nom, className: styles.nom },
						{ content: c.com, className: styles.com },
						{
							content: mois ? (
								<InfoRecurrence mois={mois.date} />
							) : semaine ? (
								<InfoRecurrence semaine={semaine.map((s) => s.jour)} />
							) : null,
						},
						{
							content: truncate(
								c.lignes.map((l) => l.produit.nom).join(', '),
								60,
							),
							className: styles.produits,
						},
						{ content: convertStatut(c.statut) },
						{
							stopPropagation: true,
							content: (
								<BtnFavori
									checked={c.favorite}
									onClick={() => toggleCommandeFavorite(c.id, c.favorite)}
									title={
										c.favorite
											? 'Enlever cette commande des favoris'
											: 'Ajouter cette commande aux favoris'
									}
								/>
							),
						},
					] as RowProps['cells'],
				} as RowProps;
			})}
		/>
	);
}

export default withRouter(_ListeCommandes);
