import React from 'react';
import { telephone } from '../../utils/telephone';
import Button from '../Button/Button';
import styles from './CallToActionTelevente.module.scss';
import { ReactComponent as SVG } from './phone.svg';

function CallToActionTelevente() {
	return (
		<p className={styles.wrapper}>
			<a href={`tel:${telephone}`}>
				<Button outline>
					<SVG className={styles.svg} />
					Un souci ? Appelez la télévente
				</Button>
			</a>
		</p>
	);
}

export default CallToActionTelevente;
