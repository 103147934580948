import React from 'react';
import MediaQuery from 'react-responsive';
import { media } from '../../../utils/breakpoints/breakpoints';
import BtnBack from '../../BtnBack/BtnBack';
import Button from '../../Button/Button';
import Dropzone from '../../Dropzone/Dropzone';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Input from '../../Form/Input/Input';
import styles from './FormulaireActualite.module.scss';

export interface Props {
	type: 'Édition' | 'Création';

	error?: string;
	loading?: boolean;

	url: string;
	titre: string;
	contenu: string;
	imageSrc: string;

	changeUrl: (val: string) => void;
	changeTitre: (val: string) => void;
	changeContenu: (val: string) => void;
	changeImageSrc: (val: string) => void;
	setImageFile: (val: File) => void;

	submit: (val: string) => void;
}

function FormulaireActualite({
	type,
	error,
	loading,
	url,
	titre,
	contenu,
	imageSrc,
	submit,
	changeUrl,
	changeTitre,
	setImageFile,
	changeContenu,
	changeImageSrc,
}: Props) {
	return (
		<>
			<header className={styles.header}>
				<MediaQuery query={media['>=tablet']}>
					<BtnBack to="/admin" className={styles.btnBack} withText />
				</MediaQuery>

				<h1 className={styles.title}>{type} d'une actualité</h1>
			</header>

			<Form onSubmit={submit} className={styles.form}>
				<div className={styles.upload}>
					<Dropzone
						imageSrc={imageSrc}
						setImageFile={setImageFile}
						changeImageSrc={changeImageSrc}
						required
					/>
				</div>

				<div className={styles.right}>
					<Input
						label="Titre"
						type="text"
						value={titre}
						setValue={changeTitre}
						className={styles.inputTitre}
						required
					/>

					<Input
						label="Contenu"
						type="textarea"
						value={contenu}
						setValue={changeContenu}
						required
					/>

					<Input label="Lien" type="text" value={url} setValue={changeUrl} />

					<ErrorMessage>{error}</ErrorMessage>

					<Button submit loading={loading} className={styles.btnSubmit}>
						{type === 'Édition' ? "Éditer l'actualité" : "Publier l'actualité"}
					</Button>
				</div>
			</Form>
		</>
	);
}

export default FormulaireActualite;
