import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	Actualite,
	ActualitesThunkResult,
	GET_ACTUALITES_FAIL,
	GET_ACTUALITES_START,
	GET_ACTUALITES_SUCCESS,
} from './types';

const query = loader('./getActualites.graphql');

export function getActualites(): ActualitesThunkResult {
	return async function (dispatch) {
		dispatch({ type: GET_ACTUALITES_START });
		try {
			type TResponse = { list: Actualite[] };
			const { list } = await request<TResponse>(query);
			dispatch({ type: GET_ACTUALITES_SUCCESS, list });
		} catch (err) {
			dispatch({ type: GET_ACTUALITES_FAIL, error: err.message });
		}
	};
}
