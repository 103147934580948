import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { downloadManyFiles } from '../../utils/download';
import request from '../../utils/request';
import { isIOS } from '../../utils/userAgent';
import ExportSelectedButton from '../ExportSelectedButton/ExportSelectedButton';
import FilterByDateRange from '../FilterByDateRange/FilterByDateRange';
import OrderBy from '../OrderBy/OrderBy';
import TableHeader from '../Table/TableHeader/TableHeader';
import TablePagination from '../Table/TablePagination/TablePagination';
import TableWrapper from '../Table/TableWrapper/TableWrapper';
import styles from './Factures.module.scss';
import ListeFactures from './ListeFactures/ListeFactures';

const getFacturesQuery = loader('./getFactures.graphql');
const getFacturesByIdQuery = loader('./getFacturesById.graphql');
const itemsPerPage = 10;

export interface Facture {
	id: string;
	filePath: string;
	numeroFacture: string;
	dateFacturation: Date;
}

export interface Props extends RouteComponentProps {
	codeClient?: string;
}

export function _Factures({ codeClient, location }: Props) {
	useTitle('Mes factures');

	const [factures, setFactures] = useState<Facture[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
	const [downloadLoading, setDownloadLoading] = useState(false);

	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';
	const dateStart = searchParams.get('dateStart');
	const dateEnd = searchParams.get('dateEnd');

	// Fetch des factures
	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		setLoading(true);

		const params = {
			skip: itemsPerPage * (pageIndex - 1),
			first: itemsPerPage,
			orderBy: `${orderBy}_${order}`,
			where: {
				client: { code: codeClient },
				...(dateStart && { dateFacturation_gte: dateStart }),
				...(dateEnd && { dateFacturation_lte: dateEnd + 'T23:59:59.999Z' }),
			},
		};

		type TResponse = { countFactures: number; factures: Facture[] };
		request<TResponse>(getFacturesQuery, params)
			.then(({ factures, countFactures }) => {
				if (!isMounted) return;
				setFactures(factures);
				setTotalCount(countFactures);
				setError('');
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [location.key, order, orderBy, codeClient, pageIndex, dateStart, dateEnd]);

	// Download des factures sélectionnées
	function downloadSelected() {
		setDownloadLoading(true);

		type TResponse = {
			factures: Pick<Facture, 'filePath' | 'numeroFacture'>[];
		};
		request<TResponse>(getFacturesByIdQuery, { codeClient, selectedIDs })
			.then(({ factures }) => {
				setSelectedIDs([]);
				downloadManyFiles(
					factures.map((f) => ({ href: f.filePath, name: f.numeroFacture })),
				);
			})
			.catch((err) => alert(err.message))
			.finally(() => setDownloadLoading(false));
	}

	if (!orderBy || !order) {
		return <Redirect to="/factures?orderBy=dateFacturation&order=DESC" />;
	}

	return (
		<TableWrapper>
			<TableHeader>
				<div className={styles.orderAndFilter}>
					<OrderBy
						fields={[
							{ name: 'Date de facturation', slug: 'dateFacturation' },
							{ name: 'Numéro de facture', slug: 'numeroFacture' },
						]}
					/>
					<FilterByDateRange label="Date" />
				</div>

				{!isIOS && (
					<ExportSelectedButton
						loading={downloadLoading}
						number={selectedIDs.length}
						onClick={downloadSelected}
					/>
				)}
			</TableHeader>

			<ListeFactures
				error={error}
				loading={loading}
				factures={factures}
				selectableRows={{ selectedIDs, setSelectedIDs }}
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { codeClient: state.compte.code };
}

export default withRouter(connect(mapStateToProps)(_Factures));
