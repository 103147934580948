import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import styles from './BtnMenu.module.scss';

export interface Props {
	id: string;
	nom?: string;
	prenom?: string;
	isMenuOpen: boolean;
	openMenu: () => void;
	closeMenu: () => void;
}

export function _BtnMenu({
	id,
	nom,
	prenom,
	isMenuOpen,
	openMenu,
	closeMenu,
}: Props) {
	const btnClasses = [styles.btn, isMenuOpen && styles.isMenuCompteOpen]
		.filter(Boolean)
		.join(' ');

	return (
		<button
			id={id}
			title={`${isMenuOpen ? 'Fermer' : 'Ouvrir'} le menu du compte`}
			className={btnClasses}
			onClick={() => (isMenuOpen ? closeMenu() : openMenu())}
		>
			<p className={styles.bonjour}>
				Bonjour <span>{prenom}</span>
			</p>

			<span className={styles.initial}>{(prenom || nom || '').charAt(0)}</span>
		</button>
	);
}

function mapStateToProps(state: AppState) {
	return {
		prenom: state.compte.prenom,
		nom: state.compte.nom,
	};
}

export default connect(mapStateToProps)(_BtnMenu);
