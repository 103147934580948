import { ThunkAction } from 'redux-thunk';

export interface Categorie {
	nom: string;
	slug: string;
	pictoSrc?: string;
}

export interface CategoriesState {
	loading: boolean;
	error: string | null;
	list: Categorie[];
}

export const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

interface GetCategoriesStartAction {
	type: typeof GET_CATEGORIES_START;
}

interface GetCategoriesSuccessAction {
	type: typeof GET_CATEGORIES_SUCCESS;
	list: Categorie[];
}

interface GetCategoriesFailAction {
	type: typeof GET_CATEGORIES_FAIL;
	error: string;
}

export type CategoriesActionTypes =
	| GetCategoriesStartAction
	| GetCategoriesSuccessAction
	| GetCategoriesFailAction;

export type CategoriesThunkResult = ThunkAction<
	any,
	CategoriesState,
	{},
	CategoriesActionTypes
>;
