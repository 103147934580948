import { ThunkAction } from 'redux-thunk';

export interface FiltresState {
	loading: boolean;
	error: string | null;
	filtres: FiltreType[];
}

export interface FiltreType {
	nom: string;
	slug: string;
	isOpen: boolean;
	options: FiltreOptionType[];
}

export interface FiltreOptionType {
	nom: string;
	slug: string;
	abreviation?: string;
}

export interface GetFiltresResponse {
	labels: FiltreOptionType[];
}

export const GET_FILTRES_START = 'GET_FILTRES_START';
export const GET_FILTRES_SUCCESS = 'GET_FILTRES_SUCCESS';
export const GET_FILTRES_FAIL = 'GET_FILTRES_FAIL';
export const OPEN_FILTRE = 'OPEN_FILTRE';
export const CLOSE_FILTRES = 'CLOSE_FILTRES';

interface GetFiltresStartAction {
	type: typeof GET_FILTRES_START;
}

interface GetFiltresSuccessAction {
	type: typeof GET_FILTRES_SUCCESS;
	filtres: FiltreType[];
}

interface GetFiltresFailAction {
	type: typeof GET_FILTRES_FAIL;
	error: string;
}

interface OpenFiltreAction {
	type: typeof OPEN_FILTRE;
	filtreSlug: string;
}

interface CloseFiltresAction {
	type: typeof CLOSE_FILTRES;
}

export type FiltresActionTypes =
	| GetFiltresStartAction
	| GetFiltresSuccessAction
	| GetFiltresFailAction
	| OpenFiltreAction
	| CloseFiltresAction;

export type FiltresThunkResult = ThunkAction<
	any,
	FiltresState,
	{},
	FiltresActionTypes
>;
