import { loader } from 'graphql.macro';
import request from '../request';

const mutation = loader('./toggleNotifications.graphql');

export const supportsPush =
	'serviceWorker' in navigator && 'PushManager' in window;

interface Props {
	disabled: boolean;
	codeClient?: string;
}

export async function toggleNotifications({ disabled, codeClient }: Props) {
	if (!supportsPush) return;

	// On get la subscription
	const registration = await navigator.serviceWorker.ready;
	const subscription = await registration.pushManager.getSubscription();
	if (!subscription) return;

	try {
		// On update la subscription
		type TResponse = { done: boolean };
		const { done }: TResponse = await request(mutation, {
			endpoint: subscription.endpoint,
			disabled,
			codeClient,
		});

		if (done) {
			// Si l'update s'est bien fait, on informe la console
			if (disabled)
				console.info(`Disabled Push Notifications (logged out user)`);
			else console.info(`Re-enabled Push Notifications (logged in user)`);
		} else {
			// Si l'update ne s'est pas fait, on unsubscribe le navigateur
			await subscription.unsubscribe();
			console.info(`Browser unsubscribed: outdated subscription`);
		}
	} catch (err) {}
}
