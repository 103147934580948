import {
	loadStateFromLocalStorage,
	saveStateToLocalStorage,
} from '../../../utils/localStorage';
import { CHANGE_NOM_PANIER, PanierActionTypes } from '../types';

export function changeNomPanier(nom: string): PanierActionTypes {
	let panier = loadStateFromLocalStorage('panier');
	if (panier) saveStateToLocalStorage('panier', { ...panier, nom });
	return { type: CHANGE_NOM_PANIER, nom };
}
