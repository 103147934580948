import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CrayonSVG } from './crayon.svg';
import styles from './NomCommande.module.scss';

export interface Props {
	nom?: string;
	loading?: boolean;
	className?: string;
	changeNom: (val: string) => void;
}

function NomCommande({ nom, changeNom, loading, className }: Props) {
	const [newNom, setNewNom] = useState('');
	const input = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setNewNom(nom || '');
	}, [nom]);

	return (
		<label
			title="Éditer le nom de la commande"
			className={[styles.wrapper, className, loading && styles.loading]
				.filter(Boolean)
				.join(' ')}
		>
			<span className={styles.picto}>
				<CrayonSVG />
			</span>

			<input
				ref={input}
				type="text"
				value={newNom}
				disabled={loading}
				className={styles.input}
				placeholder={'Nom de la commande'}
				//
				onBlur={() => changeNom(newNom)}
				onChange={(c) => setNewNom(c.target.value)}
				onKeyDown={({ key }) => key === 'Enter' && input.current!.blur()}
			/>
		</label>
	);
}

export default NomCommande;
