import {
	GET_SELECTIONS_FAIL,
	GET_SELECTIONS_START,
	GET_SELECTIONS_SUCCESS,
	SelectionsActionTypes,
	SelectionsState,
} from './types';

const initialState: SelectionsState = {
	loading: true,
	error: null,
	list: [],
};

export function selectionsReducer(
	state = initialState,
	action: SelectionsActionTypes,
): SelectionsState {
	switch (action.type) {
		case GET_SELECTIONS_START:
			return {
				...state,
				loading: true,
				error: null,
			};

		case GET_SELECTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case GET_SELECTIONS_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		default:
			return state;
	}
}
