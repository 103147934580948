import React from 'react';
import styles from './BtnCommentaire.module.scss';
import { ReactComponent as SVG } from './icon.svg';

interface Props {
	title: string;
	hasValue: boolean;
	onClick: () => void;
}

function BtnCommentaire({ onClick, hasValue, title }: Props) {
	return (
		<button
			type="button"
			title={title}
			onClick={onClick}
			className={[styles.button, hasValue && styles.hasValue]
				.filter(Boolean)
				.join(' ')}
		>
			<SVG className={styles.picto} />
		</button>
	);
}

export default BtnCommentaire;
