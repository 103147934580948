import React, { useState } from 'react';
import { connect } from 'react-redux';
import Media from 'react-responsive';
import { AppState } from '../../store';
import { media } from '../../utils/breakpoints/breakpoints';
import Alertes from '../Alertes/Alertes';
import BtnAlertes from '../BtnAlertes/BtnAlertes';
import BtnPanier from '../BtnPanier/BtnPanier';
import Logo from '../Logo/Logo';
import MenuGeneral from '../MenuGeneral/MenuGeneral';
import OmniSearch from '../OmniSearch/OmniSearch';
import styles from './Header.module.scss';
import Loupe from './Loupe/Loupe';
import PopinOmniSearch from './PopinOmniSearch/PopinOmniSearch';
import RecapRechercheOrLogo from './RecapRechercheOrLogo/RecapRechercheOrLogo';

interface Props {
	isLoggedIn: boolean;
}

export function _Header({ isLoggedIn }: Props) {
	const [omniSearchMobileOpen, setOmniSearchMobileOpen] = useState(false);

	return (
		<header className={styles.wrapper}>
			<Media query={media['<laptop']}>
				<RecapRechercheOrLogo onClick={() => setOmniSearchMobileOpen(true)} />

				<Loupe
					className={styles.loupeMobile}
					onClick={() => setOmniSearchMobileOpen(true)}
				/>

				<PopinOmniSearch
					isOpen={omniSearchMobileOpen}
					close={() => setOmniSearchMobileOpen(false)}
				/>
			</Media>

			<Media query={media['>=laptop']}>
				<Logo />

				<OmniSearch className={styles.omniSearch} />

				{isLoggedIn && (
					<>
						<BtnAlertes />
						<BtnPanier />
					</>
				)}
			</Media>

			<MenuGeneral />
			<Alertes />
		</header>
	);
}

function mapStateToProps(state: AppState) {
	return { isLoggedIn: state.compte.isLoggedIn };
}

export default connect(mapStateToProps)(_Header);
