import { ThunkAction } from 'redux-thunk';

export interface ActualitesState {
	loading: boolean;
	error: string | null;
	list: Actualite[];
}

export interface Actualite {
	id: string;
	titre: string;
	contenu: string;
	imageSrc: string;
	url: string;
}

export const GET_ACTUALITES_START = 'GET_ACTUALITES_START';
export const GET_ACTUALITES_SUCCESS = 'GET_ACTUALITES_SUCCESS';
export const GET_ACTUALITES_FAIL = 'GET_ACTUALITES_FAIL';

interface GetActualitesStartAction {
	type: typeof GET_ACTUALITES_START;
}

interface GetActualitesSuccessAction {
	type: typeof GET_ACTUALITES_SUCCESS;
	list: Actualite[];
}

interface GetActualitesFailAction {
	type: typeof GET_ACTUALITES_FAIL;
	error: string;
}

export type ActualitesActionTypes =
	| GetActualitesStartAction
	| GetActualitesSuccessAction
	| GetActualitesFailAction;

export type ActualitesThunkResult = ThunkAction<
	any,
	ActualitesState,
	{},
	ActualitesActionTypes
>;
