import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { changeLignePanier } from '../../../store/panier/actions';
import { LignePanier } from '../../../store/panier/types';
import { thinSpace } from '../../../utils/french';
import Button from '../../Button/Button';
import Input from '../../Form/Input/Input';
import CenteredPopin from '../../Popin/CenteredPopin/CenteredPopin';
import BtnCommentaire from '../BtnCommentaire/BtnCommentaire';
import styles from './EditCommentaire.module.scss';

interface Props {
	codeProduit: string;
	lignesPanier: LignePanier[];
	changeLignePanier: typeof changeLignePanier;
}

export function _EditCommentaire({
	changeLignePanier,
	codeProduit,
	lignesPanier,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);

	const { uc, commentaire, quantite } = lignesPanier.find(
		(l) => l.code === codeProduit,
	)!;

	return (
		<>
			<BtnCommentaire
				hasValue={!!commentaire}
				onClick={() => setIsOpen(true)}
				title={
					commentaire ? `Modifier le commentaire` : `Ajouter un commentaire`
				}
			/>

			<CenteredPopin isOpen={isOpen} onCacheClick={() => setIsOpen(false)}>
				<h1 className={styles.title}>Commentaire{thinSpace}:</h1>

				<Input
					label=""
					autoFocus
					type="textarea"
					value={commentaire || ''}
					className={styles.textarea}
					setValue={(val) => changeLignePanier(codeProduit, quantite, uc, val)}
				/>

				<div className={styles.validerWrapper}>
					<Button className={styles.valider} onClick={() => setIsOpen(false)}>
						Valider
					</Button>
				</div>
			</CenteredPopin>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, { changeLignePanier })(
	_EditCommentaire,
);
