import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { openFiltre } from '../../../store/filtres/actions';
import { FiltreType } from '../../../store/filtres/types';
import styles from './Filtre.module.scss';

export interface Props extends FiltreType, RouteComponentProps {
	openFiltre: typeof openFiltre;
}

export function _Filtre({ nom, slug, options, openFiltre, location }: Props) {
	const { search } = location;

	const searchParams = new URLSearchParams(search);

	const selectedSlug = searchParams.get(slug);
	const selectedOption = selectedSlug
		? options.find((o) => o.slug === selectedSlug)
		: null;

	return (
		<button
			id={`bouton-open-${slug}`}
			title={nom}
			onClick={() => openFiltre(slug)}
			className={[styles.wrapper, selectedOption && styles.selected]
				.filter(Boolean)
				.join(' ')}
		>
			{selectedOption ? selectedOption.nom : nom}
		</button>
	);
}

export default withRouter(connect(null, { openFiltre })(_Filtre));
