import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './BtnRecherche.module.scss';
import { ReactComponent as Picto } from './fruits.svg';

function BtnRecherche() {
	return (
		<NavLink
			to="/recherche"
			className={styles.button}
			activeClassName={styles.active}
			title="Recherche"
			isActive={(_, { pathname, search }) =>
				pathname === '/recherche' && !search
			}
		>
			<Picto className={styles.svg} />
		</NavLink>
	);
}

export default BtnRecherche;
