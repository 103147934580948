import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { AppState } from '../../store';
import {
	supportsPush,
	toggleNotifications,
} from '../../utils/notifications.ts/toggleNotifications';
import request from '../../utils/request';
import { urlB64ToUint8Array } from '../../utils/urlB64ToUint8Array';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import styles from './BtnNotifications.module.scss';
import { ReactComponent as SVG } from './megaphone.svg';

const createPushSubscriptionMutation = loader(
	'./createPushSubscription.graphql',
);

const applicationServerKey = urlB64ToUint8Array(
	process.env.REACT_APP_PUBLIC_VAPID_KEY || '',
);

interface Props {
	className?: string;
	codeClient?: string;
}

export function _BtnNotifications({ className, codeClient }: Props) {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const registration = useRef<ServiceWorkerRegistration>();

	// Si le navigateur supporte les notifications PushManager, on récupère la registration
	useEffect(() => {
		if (supportsPush) getRegistration();
		else console.warn(`Les notifications push ne sont pas supportées`);

		async function getRegistration() {
			registration.current = await navigator.serviceWorker.ready;
			let subscription = await registration.current.pushManager.getSubscription();
			if (subscription) toggleNotifications({ disabled: false, codeClient });
			else if (Notification.permission !== 'denied') setVisible(true);
		}
	}, [codeClient]);

	async function subscribe() {
		if (loading) return;
		setLoading(true);
		try {
			if (!registration.current) return;

			// On subscribe au service Push
			const subscription = await registration.current.pushManager.subscribe({
				applicationServerKey,
				userVisibleOnly: true,
			});

			// On récupère les données de la subscription
			const { endpoint, keys } = JSON.parse(JSON.stringify(subscription));

			// On envoie les données au backend
			await request(createPushSubscriptionMutation, {
				endpoint,
				authKey: keys.auth,
				p256dhKey: keys.p256dh,
			});
			console.info(`Push subscription created`);
		} catch (err) {
			console.error(err);
		} finally {
			setVisible(false);
		}
	}

	return (
		<CSSTransition
			in={visible}
			mountOnEnter
			unmountOnExit
			timeout={+styles.duration}
			classNames={{
				exit: styles.exit,
				enter: styles.enter,
				enterActive: styles.enterActive,
			}}
		>
			<Button
				onClick={subscribe}
				className={[styles.button, className].filter(Boolean).join(' ')}
			>
				<SVG className={styles.svg} />
				S'abonner aux notifications
				{loading ? (
					<Loader className={styles.loader} />
				) : (
					<span
						className={styles.delete}
						onClick={(e) => {
							e.stopPropagation();
							setVisible(false);
						}}
					/>
				)}
			</Button>
		</CSSTransition>
	);
}

function mapStateToProps(state: AppState) {
	return { codeClient: state.compte.code };
}

export default connect(mapStateToProps)(_BtnNotifications);
