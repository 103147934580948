import React from 'react';
import MediaQuery from 'react-responsive';
import {
	NavLink,
	Route,
	RouteComponentProps,
	withRouter,
} from 'react-router-dom';
import { media } from '../../utils/breakpoints/breakpoints';
import BtnBack from '../BtnBack/BtnBack';
import styles from './NavigationRapide.module.scss';

export function _NavigationRapide({
	location: { pathname, search },
}: RouteComponentProps) {
	const searchParams = new URLSearchParams(search);
	const from = searchParams.get('from');

	return (
		<div className={styles.wrapper}>
			<NavLink
				to="/commande-en-cours"
				title="Commande en cours"
				className={styles.link}
				activeClassName={styles.active}
			>
				Commande en cours
			</NavLink>

			<NavLink
				to="/commandes"
				title="Mes commandes"
				className={styles.link}
				activeClassName={styles.active}
				isActive={() =>
					pathname === '/commandes' ||
					(/^\/commande\/\w+$/.test(pathname) &&
						from !== '/commandes-favorites')
				}
			>
				<MediaQuery query={media['<tablet']}>
					<Route
						path="/commande/:id"
						render={() => <BtnBack className={styles.btnBackCommandes} />}
					/>
				</MediaQuery>
				Mes commandes
			</NavLink>

			<NavLink
				to="/commandes-favorites"
				title="Mes commandes favorites"
				className={styles.link}
				activeClassName={styles.active}
				isActive={() =>
					pathname === '/commandes-favorites' || from === '/commandes-favorites'
				}
			>
				<MediaQuery query={media['<tablet']}>
					<Route
						path="/commande/:id"
						render={() => <BtnBack className={styles.btnBackCommandes} />}
					/>
				</MediaQuery>
				Mes commandes favorites
			</NavLink>

			<NavLink
				to="/produits-favoris"
				title="Mes produits favoris"
				className={styles.link}
				activeClassName={styles.active}
			>
				Mes produits favoris
			</NavLink>

			<NavLink
				to="/factures"
				title="Mes factures"
				className={styles.link}
				activeClassName={styles.active}
			>
				Mes factures
			</NavLink>

			<NavLink
				to="/avoirs"
				title="Mes avoirs"
				className={styles.link}
				activeClassName={styles.active}
			>
				Mes avoirs
			</NavLink>

			{/* <NavLink
				to="/bonslivraison"
				title="Mes bons de livraison"
				className={styles.link}
				activeClassName={styles.active}
			>
				Mes bons de livraison
			</NavLink> */}
		</div>
	);
}

export default withRouter(_NavigationRapide);
