import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { logout } from '../../store/compte/actions';
import Logo from '../Logo/Logo';
import Actualites from './Actualites/Actualites';
import styles from './BackOffice.module.scss';
import CreationActualite from './CreationActualite/CreationActualite';
import EditionActualite from './EditionActualite/EditionActualite';
import { ReactComponent as ShutdownSVG } from './shutdown.svg';

export interface Props {
	logout: typeof logout;
}

export function _BackOffice({ logout }: Props) {
	return (
		<div className={styles.wrapper}>
			<header className={styles.header}>
				<Logo className={styles.logo} />

				<span className={styles.disconnect} onClick={() => logout()}>
					<ShutdownSVG />
				</span>
			</header>

			<div className={styles.content}>
				<Switch>
					<Route path="/admin/actualites" exact component={Actualites} />
					<Route
						path="/admin/actualites/creation"
						component={CreationActualite}
					/>
					<Route
						path="/admin/actualites/edition/:id"
						render={({ match }) => <EditionActualite id={match.params.id} />}
					/>
					<Redirect to="/admin/actualites" />
				</Switch>
			</div>
		</div>
	);
}

export default connect(null, { logout })(_BackOffice);
