import React from 'react';
import { convertUnite, french, thinSpace } from '../../../utils/french';
import { photosProduitsSizes } from '../../../utils/imgSizes';
import ReadCommentaire from '../../Commentaire/ReadCommentaire/ReadCommentaire';
import Img from '../../Img/Img';
import Label from '../../Label/Label';
import Prix from '../../Prix/Prix';
import Table from '../../Table/Table';
import { RowProps } from '../../Table/types';
import { LigneCommande } from '../DetailCommande';
import styles from './ListeProduits.module.scss';

export interface Props {
	error?: string;
	loading?: boolean;
	lignes: LigneCommande[];
}

function TableProduits({ error, loading, lignes }: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage="Cette commande n'existe pas"
			columns={[
				{ content: 'Photo', center: true, mobileHidden: true },
				{ content: 'Nom', mobileHidden: true },
				{ content: 'Commentaire', center: true },
				{ content: 'Labels' },
				{ content: 'Prix' },
				{ content: 'Quantité' },
				{ content: 'Total' },
			]}
			rows={lignes.map((l) => {
				const image = (
					<Img
						src={l.produit.imageSrc}
						alt={l.produit.nom}
						fallback="jpg"
						className={styles.photo}
						otherSizes={photosProduitsSizes}
						mediaQueries={[{ width: 80 }]}
					/>
				);

				const uf = l.produit.tarif && l.produit.tarif.uf;

				return {
					mobileTitle: (
						<div className={styles.mobileTitle}>
							{image}
							{l.produit.nom}
						</div>
					),

					cells: [
						// Photo produit
						{ content: image },

						// Nom
						{ className: styles.nom, content: l.produit.nom },

						// Commentaire
						{
							content: l.commentaire && (
								<ReadCommentaire value={l.commentaire} />
							),
						},

						// Labels
						{
							content: l.produit.labels.length
								? l.produit.labels.map((label, i) => (
										<Label key={i}>{label.abreviation}</Label>
								  ))
								: null,
						},

						// Prix unitaire
						{ content: <Prix afficherNA prix={l.prixUnite} unite={uf} /> },

						// Quantité
						{
							content: (
								<>
									<strong className={styles.big}>
										{french(l.quantite.toFixed(l.uc === 'K' ? 2 : 0))}
									</strong>
									{thinSpace}
									{convertUnite(l.uc, l.quantite > 1)}
								</>
							),
						},

						// Total
						{
							content: (
								<Prix
									afficherNA
									prix={
										l.uc === uf ? (l.prixUnite || 0) * l.quantite : undefined
									}
								/>
							),
						},
					],
				} as RowProps;
			})}
		/>
	);
}

export default TableProduits;
