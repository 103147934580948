import { loader } from 'graphql.macro';
import { Produit } from '../../../types/produit';
import { loadStateFromLocalStorage } from '../../../utils/localStorage';
import request from '../../../utils/request';
import {
	GET_INFO_PRODUITS_PANIER_FAIL,
	GET_INFO_PRODUITS_PANIER_START,
	GET_INFO_PRODUITS_PANIER_SUCCESS,
	LignePanier,
	LocalStoragePanier,
	PanierActionTypes,
	PanierThunkResult,
} from '../types';

const getInfoProduitsPanierQuery = loader('../getInfoProduitsPanier.graphql');

interface QueryResponse {
	produits: Produit[];
}

// Thunk & Start
export function getInfoProduitsPanier(): PanierThunkResult {
	return async function (dispatch) {
		dispatch({ type: GET_INFO_PRODUITS_PANIER_START });

		// On load le panier du local storage
		const localPanier = loadStateFromLocalStorage('panier');

		// Si pas de panier dans le local storage, on passe direct à la fin avec un panier vide
		if (!localPanier) {
			dispatch({ type: GET_INFO_PRODUITS_PANIER_SUCCESS, lignes: [] });
			return;
		}

		// Si y a un panier dans local storage, on charge les infos des produits
		try {
			const response = await request<QueryResponse>(
				getInfoProduitsPanierQuery,
				{
					codes: localPanier.lignes.map((l) => l.code),
					date: localPanier.dateLivraison,
				},
			);
			dispatch(getInfoProduitsPanierSuccess(response, localPanier));
		} catch (err) {
			dispatch({ type: GET_INFO_PRODUITS_PANIER_FAIL, error: err.message });
		}
	};
}

// Success
function getInfoProduitsPanierSuccess(
	response: QueryResponse,
	localPanier: LocalStoragePanier,
): PanierActionTypes {
	const lignes = response.produits.map((produit) => {
		const ligneInPanier = localPanier.lignes.find(
			(l) => l.code === produit.code,
		)!;
		const prixUnite = produit.tarif ? produit.tarif.prix : 0;
		return {
			nom: produit.nom,
			code: produit.code,
			imageSrc: produit.imageSrc,
			labels: produit.labels.map((l) => l.abreviation),
			uf: produit.tarif ? produit.tarif.uf : 'K',
			facteurU: produit.facteurU,
			prixUnite,
			commentaire: ligneInPanier.commentaire,
			categorie: produit.categorie,
			uc: ligneInPanier.uc,
			quantite: ligneInPanier.quantite,
			nouveauPrix: prixUnite !== ligneInPanier.prixUnite,
			disabled: produit.disabled,
			interdit: produit.isForbiddenForMe,
		} as LignePanier;
	});
	return { type: GET_INFO_PRODUITS_PANIER_SUCCESS, lignes };
}
