import React, { useState } from 'react';
import { thinSpace } from '../../../utils/french';
import CenteredPopin from '../../Popin/CenteredPopin/CenteredPopin';
import BtnCommentaire from '../BtnCommentaire/BtnCommentaire';
import styles from './ReadCommentaire.module.scss';

type Props = { value: string };

function ReadCommentaire({ value }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<BtnCommentaire
				hasValue
				title="Afficher le commentaire"
				onClick={() => setIsOpen(true)}
			/>

			<CenteredPopin isOpen={isOpen} onCacheClick={() => setIsOpen(false)}>
				<h1 className={styles.title}>Commentaire{thinSpace}:</h1>
				<p className={styles.text}>{value}</p>
			</CenteredPopin>
		</>
	);
}

export default ReadCommentaire;
