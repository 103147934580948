import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useWebSocket } from '../../hooks/useWebSocket';
import { AppState } from '../../store';
import {
	closeAlertes,
	deleteAllAlertes,
	getAlertes,
} from '../../store/alertes/actions';
import { AlertesState } from '../../store/alertes/types';
import { btnAlertesId } from '../BtnAlertes/BtnAlertes';
import Button from '../Button/Button';
import PositionedPopin from '../Popin/PositionedPopin/PositionedPopin';
import AlerteCmp from './Alerte/Alerte';
import styles from './Alertes.module.scss';

const alertesSubscription = loader('./alertesSubscription.graphql');

const nbrItemsPerPage = 4;

export interface Props extends AlertesState {
	codeClient?: string;
	getAlertes: typeof getAlertes;
	closeAlertes: typeof closeAlertes;
	deleteAllAlertes: typeof deleteAllAlertes;
}

export function _Alertes({
	list,
	error,
	isOpen,
	codeClient,
	getAlertes,
	closeAlertes,
	deleteAllAlertes,
}: Props) {
	const [page, setPage] = useState(0);

	const wasOpen = useRef(isOpen);

	// Get initial des alertes
	useEffect(() => {
		getAlertes();
	}, [getAlertes]);

	// Subscription
	useWebSocket({
		query: alertesSubscription,
		variables: { codeClient },
		callback: getAlertes,
	});

	useEffect(() => {
		// Retour direct à la page précédente si toutes les alertes d'une page sont supprimées
		if (!list[page * nbrItemsPerPage] && page !== 0) setPage(page - 1);

		// On retourne à la page 1 quand on rouvre la popin
		if (isOpen && !wasOpen.current) setPage(0);
		wasOpen.current = isOpen;
	}, [list, page, isOpen]);

	// btnAlertes position pour Popin
	const position = useRef({} as ClientRect);
	const btn = document.getElementById(btnAlertesId);
	if (btn) position.current = btn.getBoundingClientRect();

	return (
		<PositionedPopin
			isOpen={isOpen}
			onClose={closeAlertes}
			position={position.current}
		>
			{/* Header */}
			<header className={styles.header}>
				{list.length ? (
					<>
						Vous avez <strong>{list.length}</strong> alerte
						{list.length > 1 ? 's' : ''}
					</>
				) : (
					`Alertes`
				)}
			</header>

			{error ? (
				// Erreur
				<p className={styles.error}>{error}</p>
			) : (
				// Si aucune alerte
				!list.length && (
					<p className={styles.noAlerts}>Vous n'avez aucune alerte.</p>
				)
			)}

			{/* Liste des alertes */}
			{list
				.slice(page * nbrItemsPerPage, page * nbrItemsPerPage + nbrItemsPerPage)
				.map((alerte) => (
					<AlerteCmp key={alerte.id} {...alerte} />
				))}

			{/* Boutons */}
			{!!list.length && (
				<div className={styles.buttons}>
					{list.length > nbrItemsPerPage && (
						<div className={styles.navButtons}>
							<Button
								outline
								title="Page précédente"
								onClick={() => setPage(page - 1)}
								disabled={!page}
							>
								préc.
							</Button>
							<Button
								outline
								title="Page suivante"
								style={{ marginLeft: 10 }}
								onClick={() => setPage(page + 1)}
								disabled={page + 1 >= Math.ceil(list.length / nbrItemsPerPage)}
							>
								suiv.
							</Button>
						</div>
					)}

					<Button
						outline
						className={styles.deleteAll}
						onClick={() => deleteAllAlertes()}
					>
						Tout supprimer
					</Button>
				</div>
			)}
		</PositionedPopin>
	);
}

function mapStateToProps(state: AppState) {
	return { ...state.alertes, codeClient: state.compte.code };
}

export default connect(mapStateToProps, {
	closeAlertes,
	getAlertes,
	deleteAllAlertes,
})(_Alertes);
