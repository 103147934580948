import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import styles from './CallToActionDashboard.module.scss';

function CallToActionDashboard() {
	return (
		<div className={styles.wrapper}>
			<p className={styles.text}>
				La qualité toute l’année
				<br />
				dans vos assiettes
			</p>

			<Link to="/recherche">
				<Button className={styles.button} inverted>
					Je commande
				</Button>
			</Link>
		</div>
	);
}

export default CallToActionDashboard;
