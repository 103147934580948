import React from 'react';
import MediaQuery from 'react-responsive';
import { media } from '../../utils/breakpoints/breakpoints';
import TableDesktop from './TableDesktop/TableDesktop';
import TableMobile from './TableMobile/TableMobile';
import { TableProps } from './types';

function Table(props: TableProps) {
	return (
		<>
			<MediaQuery query={media['<laptop']}>
				<TableMobile {...props} />
			</MediaQuery>

			<MediaQuery query={media['>=laptop']}>
				<TableDesktop {...props} />
			</MediaQuery>
		</>
	);
}

export default Table;
