import React, { CSSProperties } from 'react';
import styles from './Count.module.scss';

export interface Props {
	count: number;
	style?: CSSProperties;
	inverted?: boolean;
}

function Count({ count, inverted, style }: Props) {
	const classes = [styles.count, inverted && styles.inverted]
		.filter(Boolean)
		.join(' ');

	return count ? (
		<span style={style} className={classes}>
			{count > 99 ? '99+' : count}
		</span>
	) : null;
}

export default Count;
