import { DocumentNode } from 'graphql';
import 'whatwg-fetch';

type Variables = { [key: string]: any };

interface GraphQLError {
	message: string;
	locations: { line: number; column: number }[];
	path: string[];
}

interface GraphQLResponse {
	data?: any;
	errors?: GraphQLError[];
	extensions?: any;
	status: number;
	[key: string]: any;
}

class ClientError extends Error {
	response: GraphQLResponse;

	constructor(response: GraphQLResponse) {
		super(ClientError.extractMessage(response));
		this.name = 'ClientError';
		this.response = response;
		if (Error.captureStackTrace) Error.captureStackTrace(this, ClientError);
	}

	private static extractMessage(response: GraphQLResponse): string {
		try {
			return response.errors![0].message;
		} catch (e) {
			return `Erreur ${response.status}`;
		}
	}
}

async function request<TResponse>(
	query: DocumentNode,
	variables?: Variables,
	file?: File,
): Promise<TResponse> {
	const body = new FormData();
	body.append('operations', JSON.stringify({ query, variables }));

	if (file) {
		body.append('map', JSON.stringify({ 0: ['variables.file'] }));
		body.append('0', file);
	} else {
		body.append('map', '{}');
	}

	const response = await fetch('/api', { method: 'POST', body });

	const contentType = response.headers.get('Content-Type');
	const isJson = contentType && contentType.startsWith('application/json');
	const result = isJson ? await response.json() : await response.text();

	if (response.ok && !result.errors && result.data) {
		return result.data;
	} else {
		const errorResult = typeof result === 'string' ? { error: result } : result;
		throw new ClientError({ ...errorResult, status: response.status });
	}
}

export default request;
