import React, { useState } from 'react';
import { JourDeLaSemaine } from '../../types';
import BtnRecurrence from './BtnRecurrence/BtnRecurrence';
import PopinRecurrence from './PopinRecurrence/PopinRecurrence';

const btnId = 'btnRecurrenceId';

export type RecurrenceMois = number;
export type RecurrenceSemaine = JourDeLaSemaine[];
export type SetRecurrence = (props: {
	mois?: RecurrenceMois;
	semaine?: RecurrenceSemaine;
}) => void;

export interface Props {
	className?: string;
	setRecurrence: SetRecurrence;
	recurrenceMois?: RecurrenceMois;
	recurrenceSemaine?: RecurrenceSemaine;
}

function Recurrence({
	className,
	recurrenceMois,
	recurrenceSemaine,
	setRecurrence,
}: Props) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<BtnRecurrence
				btnId={btnId}
				className={className}
				mois={recurrenceMois}
				semaine={recurrenceSemaine}
				onBtnClick={() => setOpen(true)}
			/>

			<PopinRecurrence
				btnId={btnId}
				isOpen={open}
				close={() => setOpen(false)}
				setRecurrence={setRecurrence}
				recurrenceMois={recurrenceMois}
				recurrenceSemaine={recurrenceSemaine}
			/>
		</>
	);
}

export default Recurrence;
