import React, { CSSProperties, ReactNode } from 'react';
import Loader from '../Loader/Loader';
import styles from './Button.module.scss';

interface CommonProps {
	children: ReactNode;
	className?: string;
	style?: CSSProperties;
	disabled?: boolean;
	inverted?: boolean;
	outline?: boolean;
	title?: string;
	id?: string;
}

interface SpanProps {
	submit?: false;
	onClick?: Function;
}

interface ButtonProps {
	submit: true;
	loading?: boolean;
}

export type Props = CommonProps & (SpanProps | ButtonProps);

function Button(props: Props) {
	const classes = [
		styles.button,
		props.className,
		props.inverted && styles.inverted,
		props.outline && styles.outline,
	];

	return !props.submit ? (
		<span
			id={props.id}
			title={props.title}
			style={props.style}
			className={[...classes, props.disabled && styles.disabled]
				.filter(Boolean)
				.join(' ')}
			onClick={() =>
				props.onClick && !props.disabled ? props.onClick() : null
			}
		>
			{props.children}
		</span>
	) : (
		<button
			id={props.id}
			type="submit"
			title={props.title}
			style={props.style}
			className={[...classes, styles.submit, props.loading && styles.loading]
				.filter(Boolean)
				.join(' ')}
			disabled={props.loading || props.disabled}
		>
			<span className={styles.text}>{props.children}</span>
			<Loader className={styles.loader} />
		</button>
	);
}

export default Button;
