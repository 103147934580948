import React from 'react';
import Loader from '../Loader/Loader';
import styles from './InitLoader.module.scss';

function InitLoader() {
	return (
		<div className={styles.wrapper}>
			<Loader />
		</div>
	);
}

export default InitLoader;
