import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from '../../../store';
import { Categorie } from '../../../store/categories/types';
import { Selection } from '../../../store/selections/types';
import BtnBack from '../../BtnBack/BtnBack';
import Logo from '../../Logo/Logo';
import styles from './RecapRechercheOrLogo.module.scss';

export interface Props extends RouteComponentProps {
	selections: Selection[];
	categories: Categorie[];
	onClick: () => void;
}

export function _RecapRechercheOrLogo({
	location,
	selections,
	categories,
	onClick,
}: Props) {
	const urlParams = new URLSearchParams(location.search);

	let type: string = '';
	let value: string = '';

	const query = urlParams.get('query');
	const selection = selections.find(
		(s) => s.slug === urlParams.get('selection'),
	);
	const categorie = categories.find(
		(c) => c.slug === urlParams.get('categorie'),
	);

	if (query) {
		type = 'Recherche :';
		value = query.charAt(0).toUpperCase() + query.slice(1);
	} else if (selection) {
		type = 'Sélection :';
		value = selection.nom;
	} else if (categorie) {
		type = 'Catégorie :';
		value = categorie.nom;
	}

	return location.pathname === '/recherche' && value ? (
		<>
			<BtnBack to="/recherche" />

			<hr className={styles.separator} />

			<div className={styles.wrapper} onClick={onClick}>
				<p className={styles.type}>{type}</p>
				<p className={styles.value}>{value}</p>
			</div>
		</>
	) : (
		<Logo className={styles.logo} />
	);
}

function mapStateToProps(state: AppState) {
	return {
		selections: state.selections.list,
		categories: state.categories.list,
	};
}

export default withRouter(connect(mapStateToProps)(_RecapRechercheOrLogo));
