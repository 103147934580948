import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { AppState } from '../../../store';
import { impersonateClient, logout } from '../../../store/compte/actions';
import { Client } from '../../../store/compte/types';
import Button from '../../Button/Button';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Loupe from '../../Header/Loupe/Loupe';
import styles from './ListeClientsCommercial.module.scss';

export interface Props {
	isLoggedInAsCommercial: boolean;
	isLoggingIn: boolean;
	error?: string;
	nom?: string;
	prenom?: string;
	clients?: Client[];
	logout: typeof logout;
	impersonateClient: typeof impersonateClient;
}

export function _ListeClientsCommercial({
	isLoggedInAsCommercial,
	isLoggingIn,
	error,
	prenom,
	nom,
	clients = [],
	logout,
	impersonateClient,
}: Props) {
	const [search, setSearch] = useState('');
	const [selectedClientCode, setSelectedClientCode] = useState<string>();

	const filteredClients = clients.filter((c) => {
		function check(string: string) {
			return string.toLowerCase().includes(search.toLowerCase());
		}
		return (
			check(c.code) ||
			check(c.prenom) ||
			check(c.nom) ||
			(c.groupe && check(c.groupe.nom))
		);
	});

	if (!isLoggedInAsCommercial) return <Redirect to="/" />;

	return (
		<Form
			className={styles.form}
			onSubmit={() =>
				selectedClientCode && impersonateClient(selectedClientCode)
			}
		>
			{/* Title */}
			<h1 className={styles.title}>
				Bienvenue {prenom} {nom}
			</h1>

			{/* Subtitle */}
			<p className={styles.subtitle}>
				Sélectionnez le client avec lequel vous souhaitez vous connecter.
			</p>

			{/* Error */}
			<ErrorMessage>{error}</ErrorMessage>

			{/* Search */}
			<label className={styles.wrapperSearch}>
				<input
					type="text"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder="Rechercher un client..."
					inputMode="search"
					className={styles.search}
				/>
				<Loupe className={styles.loupe} />
			</label>

			{/* List */}
			<ul className={styles.list}>
				{filteredClients.map((client) => (
					<li
						key={client.code}
						className={[
							styles.client,
							selectedClientCode === client.code && styles.selected,
						]
							.filter(Boolean)
							.join(' ')}
						onClick={() => setSelectedClientCode(client.code)}
					>
						{client.code} - {client.prenom} {client.nom}
						{client.groupe && (
							<span className={styles.groupe}>{client.groupe.nom}</span>
						)}
					</li>
				))}

				{/* Aucun résultat */}
				{filteredClients.length === 0 && (
					<li className={styles.noClients}>
						Aucun client ne correspond
						<br /> à votre recherche.
					</li>
				)}
			</ul>

			{/* Submit */}
			<Button submit disabled={!selectedClientCode} loading={isLoggingIn}>
				{selectedClientCode
					? `Continuer avec ${selectedClientCode}`
					: 'Continuer'}
			</Button>

			{/* Déconnexion */}
			<button className={styles.logout} onClick={logout}>
				Annuler
			</button>
		</Form>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggedInAsCommercial:
			state.compte.isLoggedIn && state.compte.type === 'Commercial',
		isLoggingIn: state.compte.isLoggingIn,
		error: state.compte.error,
		nom: state.compte.nom,
		prenom: state.compte.prenom,
		clients: state.compte.clients,
	};
}

export default connect(mapStateToProps, { impersonateClient, logout })(
	_ListeClientsCommercial,
);
