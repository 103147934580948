import { ThunkAction } from 'redux-thunk';
import { JourDeLaSemaine, Unite } from '../../types';

export interface PanierState {
	isOpen: boolean;
	nom?: string;
	com?: string;
	favorite: boolean;
	heureCommande?: string;
	dateLivraison?: Date;
	lignes: LignePanier[];
	loadingInfoProduits: boolean;
	errorInfoProduits?: string;
	recurrenceMois?: number;
	recurrenceSemaine?: JourDeLaSemaine[];
	isPopinRecurrenceOpen: boolean;
}

export interface LignePanier {
	nom: string;
	code: string;
	uf: Unite;
	uc: Unite;
	facteurU?: number;
	commentaire?: string;
	categorie: { slug: string };
	imageSrc: string;
	interdit: boolean;
	labels: string[];
	quantite: number;
	prixUnite: number;
	nouveauPrix: boolean;
	disabled: boolean;
}

export interface LocalStoragePanier {
	nom?: string;
	com?: string;
	favorite: boolean;
	heureCommande?: string;
	dateLivraison?: Date;
	recurrenceMois?: number;
	recurrenceSemaine?: JourDeLaSemaine[];
	lignes: {
		uc: Unite;
		code: string;
		quantite: number;
		prixUnite: number;
		commentaire?: string;
	}[];
}

// Open / Close Panier
export const OPEN_PANIER = 'OPEN_PANIER';
interface OpenPanierAction {
	type: typeof OPEN_PANIER;
}

export const CLOSE_PANIER = 'CLOSE_PANIER';
interface ClosePanierAction {
	type: typeof CLOSE_PANIER;
}

// Get panier
export const GET_INFO_PRODUITS_PANIER_START = 'GET_INFO_PRODUITS_PANIER_START';
interface GetInfoProduitsPanierStartAction {
	type: typeof GET_INFO_PRODUITS_PANIER_START;
}

export const GET_INFO_PRODUITS_PANIER_SUCCESS =
	'GET_INFO_PRODUITS_PANIER_SUCCESS';
interface GetInfoProduitsPanierSuccessAction {
	type: typeof GET_INFO_PRODUITS_PANIER_SUCCESS;
	lignes: LignePanier[];
}

export const GET_INFO_PRODUITS_PANIER_FAIL = 'GET_INFO_PRODUITS_PANIER_FAIL';
interface GetInfoProduitsPanierFailAction {
	type: typeof GET_INFO_PRODUITS_PANIER_FAIL;
	error: string;
}

// Manipulate Produits
export const ADD_PRODUIT_TO_PANIER = 'ADD_PRODUIT_TO_PANIER';
interface AddProduitToPanierAction {
	type: typeof ADD_PRODUIT_TO_PANIER;
	ligne: LignePanier;
}

export const CHANGE_LIGNE_PANIER = 'CHANGE_LIGNE_PANIER';
interface ChangeLignePanierAction {
	type: typeof CHANGE_LIGNE_PANIER;
	uc: Unite;
	quantite: number;
	codeProduit: string;
	commentaire?: string;
}

export const REMOVE_PRODUIT_FROM_PANIER = 'REMOVE_PRODUIT_FROM_PANIER';
interface RemoveProduitFromPanierAction {
	type: typeof REMOVE_PRODUIT_FROM_PANIER;
	codeProduit: string;
}

export const EMPTY_PANIER = 'EMPTY_PANIER';
interface EmptyPanierStartAction {
	type: typeof EMPTY_PANIER;
}

export const CHANGE_NOM_PANIER = 'CHANGE_NOM_PANIER';
interface ChangeNomPanierAction {
	type: typeof CHANGE_NOM_PANIER;
	nom: string;
}

export const CHANGE_COM_PANIER = 'CHANGE_COM_PANIER';
interface ChangeComPanierAction {
	type: typeof CHANGE_COM_PANIER;
	com: string;
}

export const CHANGE_HEURE_COMMANDE_PANIER = 'CHANGE_HEURE_COMMANDE_PANIER';
interface ChangeHeureCommandePanierAction {
	type: typeof CHANGE_HEURE_COMMANDE_PANIER;
	heureCommande: string;
}

export const CHANGE_DATE_LIVRAISON_PANIER = 'CHANGE_DATE_LIVRAISON_PANIER';
interface ChangeDateLivraisonPanierAction {
	type: typeof CHANGE_DATE_LIVRAISON_PANIER;
	date: Date;
}

export const SET_PANIER_AS_COMMANDE_FAVORITE =
	'SET_PANIER_AS_COMMANDE_FAVORITE';
interface SetPanierAsCommandeFavoriteAction {
	type: typeof SET_PANIER_AS_COMMANDE_FAVORITE;
	favorite: boolean;
}

// Récurrence
export const SET_PANIER_RECURRENCE = 'SET_PANIER_RECURRENCE';
interface SetPanierRecurrenceAction {
	type: typeof SET_PANIER_RECURRENCE;
	recurrenceMois?: number;
	recurrenceSemaine?: JourDeLaSemaine[];
}

export const REMOVE_PANIER_RECURRENCE = 'REMOVE_PANIER_RECURRENCE';
interface RemovePanierRecurrenceAction {
	type: typeof REMOVE_PANIER_RECURRENCE;
}

// Reset
export const RESET_PANIER = 'RESET_PANIER';
interface ResetPanierAction {
	type: typeof RESET_PANIER;
}

export type PanierActionTypes =
	| OpenPanierAction
	| ClosePanierAction
	| GetInfoProduitsPanierStartAction
	| GetInfoProduitsPanierSuccessAction
	| GetInfoProduitsPanierFailAction
	| AddProduitToPanierAction
	| ChangeLignePanierAction
	| RemoveProduitFromPanierAction
	| EmptyPanierStartAction
	| ChangeNomPanierAction
	| ChangeComPanierAction
	| ChangeDateLivraisonPanierAction
	| ChangeHeureCommandePanierAction
	| SetPanierAsCommandeFavoriteAction
	| SetPanierRecurrenceAction
	| RemovePanierRecurrenceAction
	| ResetPanierAction;

export type PanierThunkResult = ThunkAction<
	any,
	PanierState,
	{},
	PanierActionTypes
>;
