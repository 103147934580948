import React from 'react';
import styles from './Input.module.scss';

export interface Props {
	label: string;
	value: string;
	setValue: (value: string) => void;
	type?: 'text' | 'email' | 'textarea' | 'password' | 'url';
	minLength?: number;
	required?: boolean;
	autoFocus?: boolean;
	className?: string;
	placeholder?: string;
	pattern?: string;
	title?: string;
}

function Input({
	label,
	type = 'text',
	value,
	setValue,
	minLength,
	pattern,
	placeholder,
	required,
	autoFocus,
	className,
	title,
}: Props) {
	return (
		<label className={[styles.label, className].filter(Boolean).join(' ')}>
			{type !== 'textarea' ? (
				<input
					type={type}
					value={value}
					minLength={minLength}
					pattern={pattern}
					required={required}
					className={styles.input}
					onChange={(e) => setValue(e.target.value)}
					autoFocus={autoFocus}
					placeholder={placeholder}
					title={title}
				/>
			) : (
				<textarea
					value={value}
					minLength={minLength}
					required={required}
					className={[styles.input, styles.textarea].join(' ')}
					onChange={(e) => setValue(e.target.value)}
					placeholder={placeholder}
					autoFocus={autoFocus}
					title={title}
				/>
			)}
			<span className={styles.text}>{label}</span>
		</label>
	);
}

export default Input;
