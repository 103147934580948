import React from 'react';
import { connect } from 'react-redux';
import { removeProduitFromPanier } from '../../../store/panier/actions';
import { LignePanier } from '../../../store/panier/types';
import { photosProduitsSizes } from '../../../utils/imgSizes';
import BtnPoubelle from '../../BtnPoubelle/BtnPoubelle';
import EditCommentaire from '../../Commentaire/EditCommentaire/EditCommentaire';
import Img from '../../Img/Img';
import Label from '../../Label/Label';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import Prix from '../../Prix/Prix';
import styles from './LignePanier.module.scss';

export interface Props extends LignePanier {
	removeProduitFromPanier: typeof removeProduitFromPanier;
}

export function _LignePanier({
	code,
	quantite,
	prixUnite,
	removeProduitFromPanier,
	disabled,
	imageSrc,
	interdit,
	labels,
	nom,

	nouveauPrix,
	uc,
	uf,
}: Props) {
	if (!prixUnite) prixUnite = 0;

	return (
		<div className={styles.wrapper}>
			{/* Données du produit */}
			<div className={styles.produitData}>
				{/* Photo */}
				<Img
					src={imageSrc}
					alt={nom}
					fallback="jpg"
					className={styles.img}
					otherSizes={photosProduitsSizes}
					mediaQueries={[
						{ breakpoint: '<=mobile', width: 100 },
						{ breakpoint: '<=tablet', width: 260 },
						{ breakpoint: '<=laptop', width: 360 },
						{ breakpoint: '>laptop', width: 100 },
					]}
				/>

				{/* Nom & labels */}
				<div className={styles.description}>
					<h1 className={styles.nom}>{nom}</h1>

					{labels.map((label, i) => (
						<Label key={i}>{label}</Label>
					))}

					{/* Prix unitaire */}
					<Prix small className={styles.prix} prix={prixUnite} unite={uf} />
				</div>
			</div>

			{/* Enlever produit du panier */}
			<BtnPoubelle
				title="Enlever le produit du panier"
				className={styles.delete}
				onClick={() => removeProduitFromPanier(code)}
			/>

			{/* Si le produit est disabled */}
			{disabled && (
				<p className={styles.warning}>
					Ce produit n'existe plus.
					<br /> Veuillez le supprimer de votre panier.
				</p>
			)}

			{/* Si le produit est interdit */}
			{interdit && !disabled && (
				<p className={styles.warning}>
					Vous n'avez pas le droit de commander ce produit.
					<br /> Veuillez le supprimer de votre panier.
				</p>
			)}

			{/* Si le prix a changé depuis qu'il a été ajouté au panier */}
			{!interdit && !disabled && nouveauPrix && (
				<p className={styles.warning}>
					Le prix de ce produit a changé depuis la dernière fois.
				</p>
			)}

			{!disabled && !interdit && (
				<>
					{/* Commentaire & quantité */}
					<div className={styles.wrapperButtons}>
						{/* Commentaire */}
						<EditCommentaire codeProduit={code} />

						{/* Quantité */}
						<PlusMoinsPanier codeProduit={code} className={styles.plusMoins} />
					</div>

					{/* Total */}
					<p className={styles.total}>
						Total{' '}
						<Prix
							afficherNA
							className={styles.prixTotal}
							// Si l'unité de commande n'est pas l'unité de facturation, on n'affiche pas le total
							prix={uc === uf ? quantite * prixUnite : undefined}
						/>
					</p>
				</>
			)}
		</div>
	);
}

export default connect(null, { removeProduitFromPanier })(_LignePanier);
