import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { addProduitToPanier } from '../../store/panier/actions';
import { JourDeLaSemaine, StatutCommande, Unite } from '../../types';
import { Produit } from '../../types/produit';
import request from '../../utils/request';
import Button from '../Button/Button';
import styles from './DetailCommande.module.scss';
import Header from './Header/Header';
import ListeProduits from './ListeProduits/ListeProduits';
import PopinRepeatCommande from './PopinRepeatCommande/PopinRepeatCommande';

const getCommandeQuery = loader('./getCommande.graphql');

export interface Commande {
	nom?: string;
	numero: number;
	favorite: boolean;
	dateLivraison: Date;
	statut: StatutCommande;
	lignes: LigneCommande[];
	recurrenceMois?: { date: number };
	recurrenceSemaine?: { jour: JourDeLaSemaine }[];
}

export interface LigneCommande {
	uc: Unite;
	produit: Produit;
	quantite: number;
	prixUnite?: number;
	commentaire?: string;
}

export interface Props extends RouteComponentProps<{ id: string }> {
	addProduitToPanier: typeof addProduitToPanier;
}

export function _DetailCommande({
	addProduitToPanier,
	location: { search },
	match: {
		params: { id },
	},
}: Props) {
	const searchParams = new URLSearchParams(search);
	const recurrenceId = searchParams.get('recurrenceId') || '';
	const recurrenceDate = searchParams.get('recurrenceDate') || '';
	const from = searchParams.get('from') || '/commandes';

	const [commande, setCommande] = useState<Commande>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const [popinRecurrenceOpen, setPopinRecurrenceOpen] = useState(false);
	const [recurrenceValidee, setRecurrenceValidee] = useState(false);

	useEffect(() => {
		setError('');
		setLoading(true);

		interface TResponse {
			commande: Omit<Commande, 'dateLivraison'> & { dateLivraison: string };
			recurrenceMois?: { validee: boolean };
			recurrenceSemaine?: { validee: boolean };
		}
		request<TResponse>(getCommandeQuery, {
			id,
			recurrenceId,
			dateRecurrence: recurrenceDate ? new Date(recurrenceDate) : null,
		})
			.then(({ commande, recurrenceMois, recurrenceSemaine }) => {
				setCommande({
					...commande,
					dateLivraison: new Date(commande.dateLivraison),
				});
				if (recurrenceMois) {
					setPopinRecurrenceOpen(true);
					if (recurrenceMois.validee) setRecurrenceValidee(true);
				} else if (recurrenceSemaine) {
					setPopinRecurrenceOpen(true);
					if (recurrenceSemaine.validee) setRecurrenceValidee(true);
				}
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));
	}, [id, recurrenceId, recurrenceDate]);

	function addCommandeProduitsToPanier() {
		if (!commande) return;
		commande.lignes.forEach(({ produit, quantite, uc, commentaire }) => {
			addProduitToPanier({
				uc,
				quantite,
				commentaire,
				nom: produit.nom,
				code: produit.code,
				nouveauPrix: false,
				facteurU: produit.facteurU,
				disabled: produit.disabled,
				imageSrc: produit.imageSrc,
				categorie: produit.categorie,
				interdit: produit.isForbiddenForMe,
				uf: produit.tarif ? produit.tarif.uf : 'K',
				prixUnite: produit.tarif ? produit.tarif.prix : 0,
				labels: produit.labels.map(({ abreviation }) => abreviation),
			});
		});
	}

	return (
		<div className={styles.wrapper}>
			{/* Header */}
			<Header
				id={id}
				from={from}
				initLoading={loading}
				nom={commande && commande.nom}
				statut={commande && commande.statut}
				favorite={commande && commande.favorite}
				dateLivraison={commande && commande.dateLivraison}
				recurrenceMois={commande && commande.recurrenceMois}
				recurrenceSemaine={commande && commande.recurrenceSemaine}
				updateCommandeState={(params) => {
					setCommande({ ...commande!, ...params });
				}}
			/>

			{/* Liste des produits */}
			<ListeProduits
				error={error}
				loading={loading}
				lignes={commande ? commande.lignes : []}
			/>

			{/* Footer */}
			<footer className={styles.footer}>
				<Button
					inverted
					disabled={!commande}
					onClick={addCommandeProduitsToPanier}
					title={'Ajouter les produits de la commande au panier'}
				>
					Ajouter les produits au panier
				</Button>
			</footer>

			{/* Si on valide une commande récurrente */}
			{commande && recurrenceId && recurrenceDate && (
				<PopinRepeatCommande
					isOpen={popinRecurrenceOpen}
					validee={recurrenceValidee}
					closePopin={() => setPopinRecurrenceOpen(false)}
					commande={{
						nom: commande.nom,
						numero: commande.numero,
						lignes: commande.lignes,
					}}
					recurrence={{ id: recurrenceId, date: new Date(recurrenceDate) }}
				/>
			)}
		</div>
	);
}

export default withRouter(
	connect(null, { addProduitToPanier })(_DetailCommande),
);
