import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './ErrorMessage.module.scss';

interface Props {
	children?: string;
}

function ErrorMessage({ children }: Props) {
	const memory = useRef(children);

	useEffect(() => {
		if (children) memory.current = children;
	});

	const text = children || memory.current || '';

	const lineLength = 50;
	const linesCount = Math.ceil(text.length / lineLength);
	const height = +styles.lineHeight * linesCount + parseInt(styles.padding);

	return (
		<CSSTransition
			in={!!children}
			timeout={+styles.duration}
			classNames={styles}
			mountOnEnter
			unmountOnExit
		>
			<p className={styles.error} style={{ height }}>
				{text}
			</p>
		</CSSTransition>
	);
}

export default ErrorMessage;
