import React from 'react';
import { connect } from 'react-redux';
import {
	Redirect,
	Route,
	RouteComponentProps,
	RouteProps,
	Switch,
	withRouter,
} from 'react-router';
import { AppState } from '../../store';
import Logo from '../Logo/Logo';
import styles from './AuthenticationRoutes.module.scss';
import Connexion from './Connexion/Connexion';
import ListeClientsCommercial from './ListeClientsCommercial/ListeClientsCommercial';
import MotDePasseOublie from './MotDePasseOublie/MotDePasseOublie';
import NouveauMotDePasse from './NouveauMotDePasse/NouveauMotDePasse';

export const authRoutes: RouteProps[] = [
	{ path: '/connexion', render: () => <Connexion type="Client" /> },
	{
		path: '/connexion-commercial',
		render: () => <Connexion type="Commercial" />,
	},
	{ path: '/connexion-admin', render: () => <Connexion type="Admin" /> },
	{ path: '/mot-de-passe-oublie', component: MotDePasseOublie },
	{ path: '/nouveau-mot-de-passe', component: NouveauMotDePasse },
	{ path: '/liste-clients-commercial', component: ListeClientsCommercial },
];

export interface Props extends RouteComponentProps {
	isLoggedInAsCommercial: boolean;
}

export function _AuthenticationRoutes({
	isLoggedInAsCommercial,
	location,
}: Props) {
	// Si on est connecté en tant que commercial, on n'a accès qu'à une seule route
	if (
		isLoggedInAsCommercial &&
		location.pathname !== '/liste-clients-commercial'
	) {
		return <Redirect to="/liste-clients-commercial" />;
	}

	return (
		<div className={styles.wrapper}>
			<Logo className={styles.logo} />

			<div className={styles.content}>
				<Switch>
					{authRoutes.map((route, i) => (
						<Route key={i} {...route} />
					))}
				</Switch>
			</div>
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggedInAsCommercial:
			state.compte.isLoggedIn && state.compte.type === 'Commercial',
	};
}

export default withRouter(connect(mapStateToProps)(_AuthenticationRoutes));
