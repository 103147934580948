import formatDate from 'dateformat';
import React from 'react';
import { downloadFile } from '../../../utils/download';
import { isIOS } from '../../../utils/userAgent';
import Button from '../../Button/Button';
import Table from '../../Table/Table';
import { RowProps, TableProps } from '../../Table/types';
import { Facture } from '../Factures';
import styles from './ListeFactures.module.scss';

export interface Props {
	error?: string;
	loading: boolean;
	factures: Facture[];
	selectableRows: NonNullable<TableProps['selectableRows']>;
}

function ListeFactures({ error, factures, loading, selectableRows }: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			selectableRows={!isIOS ? selectableRows : undefined}
			noResultMessage="Vous n'avez aucune facture"
			columns={[
				{ content: 'Date de facturation' },
				{ content: 'Numéro de facture', mobileHidden: true },
				{ content: '', center: true, isButton: true },
			]}
			rows={factures.map(
				({ id, dateFacturation, filePath, numeroFacture }) =>
					({
						id,
						mobileTitle: `Facture ${numeroFacture}`,
						cells: [
							{ content: formatDate(dateFacturation, 'dd/mm/yyyy') },
							{ content: numeroFacture, className: styles.numero },
							{
								content: (
									<Button
										outline
										title={`Télécharger la facture ${numeroFacture}`}
										onClick={() => {
											if (isIOS) {
												// Si on n'utilise pas cette méthode, le PWA in-App n'affiche pas de bouton pour fermer le PDF
												// c'est une "nouveauté" de iOS 12, youpi !
												// ah oui, et on peut pas télécharger la facture une fois ouverte...
												// Merci Apple !
												window.open(filePath, '_blank');
											} else {
												downloadFile({ href: filePath, name: numeroFacture });
											}
										}}
									>
										Télécharger
									</Button>
								),
							},
						],
					} as RowProps),
			)}
		/>
	);
}

export default ListeFactures;
