import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	Client,
	CompteActionTypes,
	CompteThunkResult,
	GET_LOGGED_IN_USER_FAIL,
	GET_LOGGED_IN_USER_SUCCESS,
	HIDE_LOGIN_ERROR,
	IMPERSONATE_CLIENT_FAIL,
	IMPERSONATE_CLIENT_START,
	IMPERSONATE_CLIENT_SUCCESS,
	LoginInput,
	LOGIN_FAIL,
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGOUT_FAIL,
	LOGOUT_START,
	LOGOUT_SUCCESS,
	UserResponse,
} from './types';

const getLoggedInUserQuery = loader('./getLoggedInUser.graphql');
const loginMutation = loader('./login.graphql');
const logoutMutation = loader('./logout.graphql');
const impersonateClientMutation = loader('./impersonateClient.graphql');

export function login(credentials: LoginInput): CompteThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: LOGIN_START });
			const response = await request<UserResponse>(loginMutation, credentials);
			dispatch({ type: LOGIN_SUCCESS, response });
		} catch (err) {
			dispatch({ type: LOGIN_FAIL, error: err.message });
		}
	};
}

export function logout(): CompteThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: LOGOUT_START });
			type TResponse = { loggedOut: boolean };
			const { loggedOut } = await request<TResponse>(logoutMutation);
			if (!loggedOut) throw new Error();
			dispatch({ type: LOGOUT_SUCCESS });
		} catch (err) {
			dispatch({ type: LOGOUT_FAIL, error: err.message });
		}
	};
}

export function getLoggedInUser(): CompteThunkResult {
	return async function (dispatch) {
		try {
			const response = await request<UserResponse>(getLoggedInUserQuery);
			dispatch({ type: GET_LOGGED_IN_USER_SUCCESS, response });
		} catch (err) {
			dispatch({ type: GET_LOGGED_IN_USER_FAIL, error: err.message });
		}
	};
}

export function impersonateClient(code: string): CompteThunkResult {
	return async function (dispatch) {
		dispatch({ type: IMPERSONATE_CLIENT_START });
		try {
			type TResponse = { client: Client };
			const { client } = await request<TResponse>(impersonateClientMutation, {
				code,
			});
			dispatch({ type: IMPERSONATE_CLIENT_SUCCESS, client });
		} catch (err) {
			dispatch({ type: IMPERSONATE_CLIENT_FAIL, error: err.message });
		}
	};
}

export function hideLoginError(): CompteActionTypes {
	return { type: HIDE_LOGIN_ERROR };
}
