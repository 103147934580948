import React from 'react';
import styles from './CheckboxRecurrence.module.scss';

export interface Props {
	children: string;
	checked: boolean;
	onClick: Function;
}

export function CheckboxRecurrence({ checked, children, onClick }: Props) {
	return (
		<div
			onClick={() => onClick()}
			className={[styles.wrapper, checked && styles.checked]
				.filter(Boolean)
				.join(' ')}
		>
			{children}
			<span className={styles.checkbox} />
		</div>
	);
}

export default CheckboxRecurrence;
