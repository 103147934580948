import {
	CLOSE_FILTRES,
	FiltresActionTypes,
	FiltresState,
	GET_FILTRES_FAIL,
	GET_FILTRES_START,
	GET_FILTRES_SUCCESS,
	OPEN_FILTRE,
} from './types';

const initialState: FiltresState = {
	loading: false,
	error: null,
	filtres: [],
};

export function filtresReducer(
	state = initialState,
	action: FiltresActionTypes,
): FiltresState {
	switch (action.type) {
		case GET_FILTRES_START:
			return {
				...state,
				loading: true,
				error: null,
			};

		case GET_FILTRES_SUCCESS:
			return {
				...state,
				loading: false,
				filtres: action.filtres,
			};

		case GET_FILTRES_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		case OPEN_FILTRE:
			return {
				...state,
				filtres: state.filtres.map((filtre) => {
					if (filtre.slug === action.filtreSlug) {
						filtre.isOpen = true;
					}
					return filtre;
				}),
			};

		case CLOSE_FILTRES:
			return {
				...state,
				filtres: state.filtres.map((filtre) => ({ ...filtre, isOpen: false })),
			};

		default:
			return state;
	}
}
