import React from 'react';
import { Unite } from '../../types';
import { convertUnite, french, thinSpace } from '../../utils/french';
import styles from './Prix.module.scss';

export interface Props {
	prix?: number;
	unite?: Unite;
	small?: boolean;
	className?: string;
	afficherNA?: boolean;
}

function Prix({ prix, unite, className, afficherNA, small }: Props) {
	return prix ? (
		<strong
			className={[className, small && styles.small].filter(Boolean).join(' ')}
		>
			<span className={styles.big}>{french(prix.toFixed(2))}</span>
			{thinSpace}€{unite && thinSpace + '/' + thinSpace + convertUnite(unite)}
		</strong>
	) : afficherNA ? (
		<strong
			title="Prix non disponible"
			className={[className, styles.na].filter(Boolean).join(' ')}
		>
			N/A
		</strong>
	) : null;
}

export default Prix;
