import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
	const middlewareEnhancer = applyMiddleware(thunk);

	const store = createStore(
		rootReducer,
		composeWithDevTools(middlewareEnhancer),
	);

	if (process.env.NODE_ENV !== 'production') {
		if ((module as any).hot) {
			(module as any).hot.accept('./rootReducer', () => {
				store.replaceReducer(rootReducer);
			});
		}
	}

	return store;
}
