import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import {
	changeLignePanier,
	removeProduitFromPanier,
} from '../../store/panier/actions';
import { LignePanier } from '../../store/panier/types';
import { Unite } from '../../types';
import { convertUnite } from '../../utils/french';
import styles from './PlusMoinsPanier.module.scss';

const qteMax = 9999;

export interface Props {
	codeProduit: string;
	className?: string;
	lignesPanier: LignePanier[];
	removeProduitFromPanier: typeof removeProduitFromPanier;
	changeLignePanier: typeof changeLignePanier;
}

export function _PlusMoinsPanier({
	codeProduit,
	className,
	lignesPanier,
	removeProduitFromPanier,
	changeLignePanier,
}: Props) {
	let { uc, uf, quantite, commentaire, facteurU } = lignesPanier.find(
		(l) => l.code === codeProduit,
	)!;

	if (!uc) uc = uf;

	const [inputQte, setInputQte] = useState<string>(quantite.toString());
	const [inputWidth, setInputWidth] = useState<number>();

	const hidden = useRef<HTMLSpanElement>(null);

	const unites: { u: Unite; w: number }[] = [
		{ u: 'K', w: 32 },
		{ u: 'U', w: 59 },
		{ u: 'C', w: 46 },
	];

	function getMultiple(quantité: number) {
		return facteurU ? Math.ceil(quantité / facteurU) * facteurU : quantité;
	}

	useEffect(() => {
		setInputQte(quantite.toString());
	}, [quantite]);

	useEffect(() => {
		if (!hidden.current) return;
		const { width } = hidden.current.getBoundingClientRect();
		setInputWidth(width);
	}, [inputQte]);

	const input = useRef<HTMLInputElement>(null);

	const isKg = uc === 'K';

	function setQuantite(qte: number) {
		if (!isKg) qte = Math.ceil(qte);
		changeLignePanier(codeProduit, qte, uc, commentaire);
		setInputQte(qte.toString());
	}

	const unite = unites.find((u) => u.u === uc);

	return (
		<div className={[styles.wrapper, className].filter(Boolean).join(' ')}>
			{/* Moins */}
			<button
				className={styles.moins}
				onClick={() => {
					const facteur = (uc === 'U' && facteurU) || 1;
					if (quantite - facteur > 0)
						setQuantite(Math.ceil(quantite - facteur));
					else removeProduitFromPanier(codeProduit);
				}}
			/>

			{/* Input quantité */}
			<label className={styles.labelInput}>
				{/* Hidden for size */}
				<span ref={hidden} className={styles.hiddenInput}>
					{inputQte.replace('.', ',')}
				</span>

				{/* Input */}
				<input
					ref={input}
					type="text"
					inputMode="decimal"
					value={inputQte.replace('.', ',')}
					className={styles.inputQuantite}
					style={{ width: inputWidth }}
					onChange={(e) => setInputQte(e.target.value.replace(' ', ''))}
					onKeyDown={(e) => e.key === 'Enter' && input.current!.blur()}
					onBlur={() => {
						let qte = +inputQte.replace(',', '.');
						qte = Math.floor(qte * 100) / 100;
						qte = Math.min(qteMax, Math.max(0, qte));
						if (uc === 'U') qte = getMultiple(qte);

						// Si la qte est > 0, on update le panier
						// Si la qte est 0, on enlève le produit
						// Si la qte n'est pas valide, on remet la quantite précédente
						if (qte > 0) setQuantite(qte);
						else if (qte === 0) removeProduitFromPanier(codeProduit);
						else setInputQte(quantite.toString());
					}}
				/>

				{/* Dash */}
				<div className={styles.dash} />
			</label>

			<label className={styles.uc}>
				<select
					value={uc}
					className={styles.select}
					style={{ width: unite ? unite.w : 0 }}
					onChange={(e) => {
						const uc = e.target.value as Unite;
						const qte =
							uc === 'K'
								? quantite
								: uc === 'U'
								? Math.ceil(getMultiple(quantite))
								: Math.ceil(quantite);
						changeLignePanier(codeProduit, qte, uc, commentaire);
					}}
				>
					{unites.map(({ u }) => (
						<option key={u} value={u}>
							{convertUnite(u, quantite > 1)}
						</option>
					))}
				</select>
			</label>

			{/* Plus */}
			<button
				className={styles.plus}
				disabled={quantite >= qteMax}
				onClick={() =>
					setQuantite(Math.floor(quantite + ((uc === 'U' && facteurU) || 1)))
				}
			/>
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, {
	removeProduitFromPanier,
	changeLignePanier,
})(_PlusMoinsPanier);
