type DownloadArgs = { href: string; name: string };

export function downloadFile(file: DownloadArgs) {
	const link = document.createElement('a');
	link.href = file.href;
	link.download = file.name;
	link.target = '_blank';
	link.click();
}

export function downloadManyFiles(files: DownloadArgs[]) {
	function downloadNext(i: number) {
		if (i < files.length) {
			downloadFile(files[i]);
			setTimeout(() => downloadNext(i + 1), 500);
		}
	}

	downloadNext(0);
}
