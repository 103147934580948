import React from 'react';
import { ReactComponent as LoupeSVG } from './loupe-mc-gestion.svg';
import styles from './Loupe.module.scss';

export interface Props {
	type?: 'button' | 'submit';
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
}

function Loupe({
	disabled = false,
	type = 'button',
	className,
	onClick,
}: Props) {
	return (
		<button
			type={type}
			onClick={onClick}
			disabled={disabled}
			className={[styles.button, styles.btnOpenOnMobile, className]
				.filter(Boolean)
				.join(' ')}
		>
			<LoupeSVG className={styles.svg} />
		</button>
	);
}

export default Loupe;
