import React, { DragEvent, useState } from 'react';
import styles from './Dropzone.module.scss';
import { ReactComponent as UploadSVG } from './upload.svg';

export interface Props {
	imageSrc?: string;
	required?: boolean;
	setImageFile: (file: File) => void;
	changeImageSrc: (src: string) => void;
}

function Dropzone({ imageSrc, required, changeImageSrc, setImageFile }: Props) {
	const [highlight, setHighlight] = useState(false);

	function handleFiles(files: FileList | null) {
		if (!files || !files.length) return;
		const file = files[0];
		if (
			!['image/png', 'image/jpeg'].includes(file.type) ||
			file.size > 10000000 // 10Mo
		)
			return;
		setImageFile(file);
		changeImageSrc(URL.createObjectURL(file));
	}

	function onDragOver(e: DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		setHighlight(true);
	}

	function onDrop(e: DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		handleFiles(e.dataTransfer.files);
		setHighlight(false);
	}

	const cta = (
		<div className={styles.cta}>
			<UploadSVG className={styles.icon} />
			<p>Téléverser une image (jpeg ou png, 10Mo max)</p>
		</div>
	);

	return (
		<label
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragLeave={() => setHighlight(false)}
			className={[styles.wrapper, highlight && styles.highlight]
				.filter(Boolean)
				.join(' ')}
		>
			{imageSrc ? (
				<div className={styles.img}>
					<img alt="" src={imageSrc} />
					{cta}
				</div>
			) : (
				<div className={styles.dropzone}>{cta}</div>
			)}

			<input
				type="file"
				className={styles.input}
				accept="image/jpeg, image/png"
				onChange={({ target: { files } }) => handleFiles(files)}
				required={required && !imageSrc}
			/>
		</label>
	);
}

export default Dropzone;
