import React from 'react';
import Checkbox from '../../../Checkbox/Checkbox';
import { ColumnProps, RowProps } from '../../types';
import Buttons from '../Buttons/Buttons';
import styles from './Block.module.scss';

type Props = Omit<RowProps, 'id'> & {
	columns: Omit<ColumnProps, 'center'>[];
	select?: { selected: boolean; toggle: () => void };
};

function Block({
	href,
	cells,
	select,
	columns,
	mobileTitle,
	specialMessageOnMobile,
}: Props) {
	return (
		<div className={styles.wrapper}>
			{/* Header */}
			<header className={styles.header}>
				{select && (
					<Checkbox
						onClick={select.toggle}
						checked={select.selected}
						className={styles.checkbox}
					/>
				)}
				{mobileTitle}
			</header>

			{/* Body */}
			{cells.map(({ content, className }, o) => {
				const col = columns[o];
				const visible = !col.mobileHidden && !col.isButton && content;
				return (
					visible && (
						<div key={o} className={styles.row}>
							<p className={styles.key}>{col && col.content}</p>

							<div
								className={[styles.value, className].filter(Boolean).join(' ')}
							>
								{content}
							</div>
						</div>
					)
				);
			})}

			{specialMessageOnMobile}

			{/* Buttons */}
			<Buttons cells={cells} columns={columns} href={href} />
		</div>
	);
}

export default Block;
