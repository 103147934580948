import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import PopinPortal from '../PopinPortal';
import styles from './CenteredPopin.module.scss';

export interface Props {
	isOpen: boolean;
	children: ReactNode;
	onCacheClick: Function;
}

function CenteredPopin({ isOpen, children, onCacheClick }: Props) {
	return (
		<PopinPortal>
			<CSSTransition
				in={isOpen}
				timeout={+styles.duration}
				mountOnEnter
				unmountOnExit
				classNames={{
					enter: styles.enter,
					enterActive: styles.enterActive,
					exit: styles.exit,
				}}
			>
				<div className={styles.wrapper}>
					{/* CACHE */}
					<div className={styles.cache} onClick={() => onCacheClick()} />

					{/* CONTENT */}
					<div className={styles.content}>{children}</div>
				</div>
			</CSSTransition>
		</PopinPortal>
	);
}

export default CenteredPopin;
