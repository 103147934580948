import {
	AlertesActionTypes,
	AlertesState,
	CLOSE_ALERTES,
	DELETE_ALERTE_FAIL,
	DELETE_ALERTE_START,
	DELETE_ALERTE_SUCCESS,
	DELETE_ALL_ALERTES_FAIL,
	DELETE_ALL_ALERTES_START,
	DELETE_ALL_ALERTES_SUCCESS,
	GET_ALERTES_FAIL,
	GET_ALERTES_START,
	GET_ALERTES_SUCCESS,
	OPEN_ALERTES,
	READ_ALERTE_START,
} from './types';

const initialState: AlertesState = {
	isOpen: false,
	list: [],
	loading: false,
	error: null,
};

export function alertesReducer(
	state = initialState,
	action: AlertesActionTypes,
): AlertesState {
	switch (action.type) {
		case OPEN_ALERTES:
			return {
				...state,
				isOpen: true,
			};

		case CLOSE_ALERTES:
			return {
				...state,
				isOpen: false,
			};

		// GET
		case GET_ALERTES_START:
			return {
				...state,
				loading: true,
				error: null,
			};

		case GET_ALERTES_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case GET_ALERTES_FAIL:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		// READ
		case READ_ALERTE_START:
			return {
				...state,
				list: state.list.map((a) => {
					if (a.id === action.id) a.lu = true;
					return a;
				}),
			};

		// DELETE
		case DELETE_ALERTE_START:
			return {
				...state,
				list: state.list.map((alerte) => {
					if (alerte.id === action.id) alerte.deleting = true;
					return alerte;
				}),
			};

		case DELETE_ALERTE_SUCCESS:
			return {
				...state,
				list: state.list.filter((el) => el.id !== action.id),
			};

		case DELETE_ALERTE_FAIL:
			return {
				...state,
				error: action.error,
				list: state.list.map((alerte) => ({ ...alerte, deleting: false })),
			};

		// DELETE ALL
		case DELETE_ALL_ALERTES_START:
			return {
				...state,
				list: state.list.map((alerte) => ({ ...alerte, deleting: true })),
			};

		case DELETE_ALL_ALERTES_SUCCESS:
			return {
				...state,
				list: [],
			};

		case DELETE_ALL_ALERTES_FAIL:
			return {
				...state,
				error: action.error,
				list: state.list.map((alerte) => ({ ...alerte, deleting: false })),
			};

		default:
			return state;
	}
}
