import formatDate from 'dateformat';
import React from 'react';
import { downloadFile } from '../../../utils/download';
import { isIOS } from '../../../utils/userAgent';
import Button from '../../Button/Button';
import Table from '../../Table/Table';
import { RowProps, TableProps } from '../../Table/types';
import { Avoir } from '../Avoirs';
import styles from './ListeAvoirs.module.scss';

export interface Props {
	error?: string;
	loading: boolean;
	avoirs: Avoir[];
	selectableRows: NonNullable<TableProps['selectableRows']>;
}

function ListeAvoirs({ error, avoirs, loading, selectableRows }: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			selectableRows={!isIOS ? selectableRows : undefined}
			noResultMessage="Vous n'avez aucun avoir"
			columns={[
				{ content: "Date d'avoir" },
				{ content: "Numéro d'avoir", mobileHidden: true },
				{ content: '', center: true, isButton: true },
			]}
			rows={avoirs.map(
				({ id, dateAvoir, filePath, numeroAvoir }) =>
					({
						id,
						mobileTitle: `Avoir ${numeroAvoir}`,
						cells: [
							{ content: formatDate(dateAvoir, 'dd/mm/yyyy') },
							{ content: numeroAvoir, className: styles.numero },
							{
								content: (
									<Button
										outline
										title={`Télécharger l'avoir ${numeroAvoir}`}
										onClick={() => {
											if (isIOS) {
												// Si on n'utilise pas cette méthode, le PWA in-App n'affiche pas de bouton pour fermer le PDF
												// c'est une "nouveauté" de iOS 12, youpi !
												// ah oui, et on peut pas télécharger l'avoir une fois ouverte...
												// Merci Apple !
												window.open(filePath, '_blank');
											} else {
												downloadFile({ href: filePath, name: numeroAvoir });
											}
										}}
									>
										Télécharger
									</Button>
								),
							},
						],
					} as RowProps),
			)}
		/>
	);
}

export default ListeAvoirs;
