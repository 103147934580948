import React from 'react';
import styles from './BtnPoubelle.module.scss';
import { ReactComponent as SVG } from './poubelle-mc-gestion.svg';

interface Props {
	title: string;
	onClick: Function;
	className?: string;
}

function BtnPoubelle({ className, title, onClick }: Props) {
	return (
		<button
			type="button"
			title={title}
			onClick={() => onClick()}
			className={[styles.wrapper, className].filter(Boolean).join(' ')}
		>
			<SVG className={styles.picto} />
		</button>
	);
}

export default BtnPoubelle;
