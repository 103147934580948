import React from 'react';
import styles from './Checkbox.module.scss';

interface Props {
	checked: boolean;
	children?: string;
	className?: string;
	halfChecked?: boolean;
	onClick: (checked: boolean) => void;
}

export function Checkbox({
	checked,
	onClick,
	children,
	className,
	halfChecked,
}: Props) {
	return (
		<label
			className={[
				styles.wrapper,
				className,
				checked && styles.checked,
				halfChecked && !checked && styles.halfChecked,
			]
				.filter(Boolean)
				.join(' ')}
		>
			<input
				type="checkbox"
				checked={checked}
				className={styles.input}
				onChange={() => onClick(halfChecked ? false : !checked)}
			/>
			<span className={styles.checkbox} />

			{children && <p className={styles.text}>{children}</p>}
		</label>
	);
}

export default Checkbox;
