import React, { ReactNode } from 'react';
import styles from './TableWrapper.module.scss';

interface Props {
	children: ReactNode;
}

function TableWrapper({ children }: Props) {
	return <div className={styles.wrapper}>{children}</div>;
}

export default TableWrapper;
