import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { addProduitToPanier } from '../../../store/panier/actions';
import { LignePanier } from '../../../store/panier/types';
import { Produit } from '../../../types/produit';
import { photosProduitsSizes } from '../../../utils/imgSizes';
import BtnAjoutPanier from '../../BtnAjoutPanier/BtnAjoutPanier';
import BtnFavori from '../../BtnFavori/BtnFavori';
import Img from '../../Img/Img';
import Label from '../../Label/Label';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import Prix from '../../Prix/Prix';
import Table from '../../Table/Table';
import { RowProps } from '../../Table/types';
import styles from './ListeProduits.module.scss';

export interface Props {
	error?: string;
	loading: boolean;
	produits: Produit[];
	lignesPanier: LignePanier[];
	addProduitToPanier: typeof addProduitToPanier;
	setProduitFavori: (id: string, isFavorite: boolean) => void;
}

export function _ListeProduits({
	error,
	loading,
	produits,
	lignesPanier,
	setProduitFavori,
	addProduitToPanier,
}: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage="Vous n'avez aucun produit favori"
			columns={[
				{ content: 'Photo', center: true, mobileHidden: true },
				{ content: 'Nom', mobileHidden: true },
				{ content: 'Labels' },
				{ content: 'Prix' },
				{ content: 'Panier', center: true, isButton: true },
				{ content: 'Favori', center: true, isButton: true },
			]}
			rows={produits.map((produit) => {
				const image = (
					<Img
						src={produit.imageSrc}
						alt={produit.nom}
						fallback="jpg"
						className={styles.photo}
						otherSizes={photosProduitsSizes}
						mediaQueries={[{ width: 80 }]}
					/>
				);

				const prix = produit.tarif ? produit.tarif.prix : 0;
				const isInPanier = lignesPanier.find((l) => l.code === produit.code);
				const uf = produit.tarif && produit.tarif.uf;

				return {
					mobileTitle: (
						<div className={styles.mobileTitle}>
							{image}
							{produit.nom}
						</div>
					),
					cells: [
						{ content: image },
						{ content: produit.nom, className: styles.nom },
						{
							content: produit.labels.length
								? produit.labels.map((label, i) => (
										<Label key={i}>{label.abreviation}</Label>
								  ))
								: null,
						},
						{ content: <Prix prix={prix} unite={uf} /> },
						{
							content: isInPanier ? (
								<PlusMoinsPanier codeProduit={produit.code} />
							) : (
								<BtnAjoutPanier
									title="Ajouter le produit au panier"
									onClick={() => {
										const uc = uf || produit.ucParDefaut;
										return addProduitToPanier({
											code: produit.code,
											imageSrc: produit.imageSrc,
											nom: produit.nom,
											uf: uf || 'K',
											facteurU: produit.facteurU,
											categorie: produit.categorie,
											interdit: false,
											disabled: false,
											prixUnite: prix,
											nouveauPrix: false,
											uc,
											labels: produit.labels.map((l) => l.abreviation),
											quantite: (uc === 'U' && produit.facteurU) || 1,
										});
									}}
								/>
							),
						},
						{
							content: (
								<BtnFavori
									checked={produit.isFavoriteForMe}
									onClick={() => {
										setProduitFavori(produit.code, produit.isFavoriteForMe);
									}}
									title={
										produit.isFavoriteForMe
											? 'Enlever le produit de mes favoris'
											: 'Ajouter le produit à mes favoris'
									}
								/>
							),
						},
					],
				} as RowProps;
			})}
		/>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, { addProduitToPanier })(_ListeProduits);
