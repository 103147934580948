import { CHECK_IF_ONLINE, IsOnlineActionTypes } from './types';

const initialState: boolean = navigator.onLine;

export function isOnlineReducer(
	state = initialState,
	action: IsOnlineActionTypes,
): boolean {
	switch (action.type) {
		case CHECK_IF_ONLINE:
			return navigator.onLine;
		default:
			return state;
	}
}
