import { ThunkAction } from 'redux-thunk';

export interface CompteState {
	nom?: string;
	prenom?: string;
	code?: string;
	clients?: Client[];

	type: UserType;
	error?: string;

	isLoggingIn: boolean;
	isLoggedIn: boolean;
	isGettingLoggedInUser: boolean;
	isLoggingOut: boolean;
}

export type UserType = 'Client' | 'Commercial' | 'Admin';

export interface LoginInput {
	code: string;
	password: string;
	type: UserType;
}

interface UserCommon {
	nom: string;
	prenom: string;
}

export interface Client extends UserCommon {
	code: string;
	groupe?: {
		nom: string;
	};
}

interface Commercial extends UserCommon {
	code: string;
	clients: Client[];
}

type Admin = UserCommon;

export interface UserResponse {
	user: { type: UserType } & (Client | Commercial | Admin);
}

export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
interface GetLoggedInUserSuccessAction {
	type: typeof GET_LOGGED_IN_USER_SUCCESS;
	response: UserResponse | { user: null };
}

export const GET_LOGGED_IN_USER_FAIL = 'GET_LOGGED_IN_USER_FAIL';
interface GetLoggedInUserFailAction {
	type: typeof GET_LOGGED_IN_USER_FAIL;
	error: string;
}

export const LOGIN_START = 'LOGIN_START';
interface LoginStartAction {
	type: typeof LOGIN_START;
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
interface LoginSuccessAction {
	type: typeof LOGIN_SUCCESS;
	response: UserResponse;
}

export const LOGIN_FAIL = 'LOGIN_FAIL';
interface LoginFailAction {
	type: typeof LOGIN_FAIL;
	error: string;
}

export const LOGOUT_START = 'LOGOUT_START';
interface LogoutStartAction {
	type: typeof LOGOUT_START;
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
interface LogoutSuccessAction {
	type: typeof LOGOUT_SUCCESS;
}

export const LOGOUT_FAIL = 'LOGOUT_FAIL';
interface LogoutFailAction {
	type: typeof LOGOUT_FAIL;
	error: string;
}

export const HIDE_LOGIN_ERROR = 'HIDE_LOGIN_ERROR';
interface HideLoginErrorAction {
	type: typeof HIDE_LOGIN_ERROR;
}

export const IMPERSONATE_CLIENT_START = 'IMPERSONATE_CLIENT_START';
interface ImpersonateClientStartAction {
	type: typeof IMPERSONATE_CLIENT_START;
}

export const IMPERSONATE_CLIENT_SUCCESS = 'IMPERSONATE_CLIENT_SUCCESS';
interface ImpersonateClientSuccessAction {
	type: typeof IMPERSONATE_CLIENT_SUCCESS;
	client: Client;
}

export const IMPERSONATE_CLIENT_FAIL = 'IMPERSONATE_CLIENT_FAIL';
interface ImpersonateClientFailAction {
	type: typeof IMPERSONATE_CLIENT_FAIL;
	error: string;
}

export type CompteActionTypes =
	| GetLoggedInUserSuccessAction
	| GetLoggedInUserFailAction
	| LoginStartAction
	| LoginSuccessAction
	| LoginFailAction
	| HideLoginErrorAction
	| LogoutStartAction
	| LogoutSuccessAction
	| LogoutFailAction
	| ImpersonateClientStartAction
	| ImpersonateClientSuccessAction
	| ImpersonateClientFailAction;

export type CompteThunkResult = ThunkAction<
	any,
	CompteState,
	{},
	CompteActionTypes
>;
