import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../store';
import { getLoggedInUser } from '../../store/compte/actions';
import { UserType } from '../../store/compte/types';
import { checkIfOnline } from '../../store/isOnline/actions';
import AuthenticationRoutes, {
	authRoutes,
} from '../AuthenticationRoutes/AuthenticationRoutes';
import BackOffice from '../BackOffice/BackOffice';
import InitLoader from '../InitLoader/InitLoader';
import MainLayout from '../MainLayout/MainLayout';
import NoInternet from '../NoInternet/NoInternet';

export interface Props extends RouteComponentProps {
	isOnline: boolean;
	isLoggedIn: boolean;
	isGettingLoggedInUser: boolean;
	userType: UserType;

	checkIfOnline: typeof checkIfOnline;
	getLoggedInUser: typeof getLoggedInUser;
}

export function _App({
	location: { pathname },
	isGettingLoggedInUser,
	isOnline,
	userType,
	isLoggedIn,
	checkIfOnline,
	getLoggedInUser,
}: Props) {
	useEffect(() => {
		// On check si le user est loggedIn avant toute chose
		getLoggedInUser();

		// Online / Offline
		window.addEventListener('online', checkIfOnline);
		window.addEventListener('offline', checkIfOnline);
		return () => {
			window.removeEventListener('online', checkIfOnline);
			window.removeEventListener('offline', checkIfOnline);
		};
	}, [getLoggedInUser, checkIfOnline]);

	// On n'affiche rien tant que l'on ne sait pas si l'utilisateur est connecté ou non
	if (isGettingLoggedInUser) return <InitLoader />;

	// Redirect to connexion if not logged in, except for authentication routes
	const isAuthRoute = authRoutes.map((r) => r.path).includes(pathname);
	if (!isLoggedIn && !isAuthRoute) return <Redirect to="/connexion" />;

	return (
		<>
			{isAuthRoute || userType === 'Commercial' ? (
				// Si on est sur une route d'authentication ou qu'on est commercial
				<AuthenticationRoutes />
			) : userType === 'Client' ? (
				// Si on est connecté en tant que client
				<MainLayout />
			) : (
				// Si on est connecté en tant qu'admin
				<BackOffice />
			)}

			{!isOnline && <NoInternet />}
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isOnline: state.isOnline,
		isLoggedIn: state.compte.isLoggedIn,
		isGettingLoggedInUser: state.compte.isGettingLoggedInUser,
		userType: state.compte.type,
	};
}

export default withRouter(
	connect(mapStateToProps, { getLoggedInUser, checkIfOnline })(_App),
);
