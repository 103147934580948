import React, { useEffect, useRef, useState } from 'react';
import { JourDeLaSemaine } from '../../../types';
import { joursDeLaSemaine } from '../../../utils/french';
import Button from '../../Button/Button';
import PositionedPopin from '../../Popin/PositionedPopin/PositionedPopin';
import Radio from '../../Radio/Radio';
import { ReactComponent as PictoHorloge } from '../BtnRecurrence/horloge.svg';
import CheckboxRecurrence from '../CheckboxRecurrence/CheckboxRecurrence';
import {
	RecurrenceMois,
	RecurrenceSemaine,
	SetRecurrence,
} from '../Recurrence';
import styles from './PopinRecurrence.module.scss';

export interface Props {
	btnId: string;
	isOpen: boolean;
	close: () => void;
	setRecurrence: SetRecurrence;
	recurrenceMois?: RecurrenceMois;
	recurrenceSemaine?: RecurrenceSemaine;
}

export function PopinRecurrence({
	btnId,
	close,
	isOpen,
	setRecurrence,
	recurrenceMois,
	recurrenceSemaine,
}: Props) {
	type TypeRecurrence = 'JOURS_DE_LA_SEMAINE' | 'JOUR_DANS_LE_MOIS' | undefined;

	const [type, setType] = useState<TypeRecurrence>();

	const [dayOfMonth, setDayOfMonth] = useState<number | undefined>();
	const [daysOfWeek, setDaysOfWeek] = useState<JourDeLaSemaine[]>([]);

	useEffect(() => {
		setDayOfMonth(recurrenceMois);
		setDaysOfWeek(recurrenceSemaine || []);
		setType(
			recurrenceSemaine && recurrenceSemaine.length
				? 'JOURS_DE_LA_SEMAINE'
				: recurrenceMois
				? 'JOUR_DANS_LE_MOIS'
				: undefined,
		);
	}, [recurrenceMois, recurrenceSemaine]);

	function confirmAndClose() {
		close();

		if (type === 'JOURS_DE_LA_SEMAINE') {
			setRecurrence({ semaine: daysOfWeek });
		} else if (type === 'JOUR_DANS_LE_MOIS') {
			setRecurrence({ mois: dayOfMonth! });
		} else {
			setRecurrence({});
		}
	}

	// Position bouton récurrence
	const position = useRef({} as ClientRect);
	const btn = document.getElementById(btnId);
	if (btn) position.current = btn.getBoundingClientRect();

	return (
		<PositionedPopin
			isOpen={isOpen}
			onClose={() => null}
			position={Object.assign(position.current, { height: 0 })}
		>
			{/* Header */}
			<header className={styles.header}>
				<PictoHorloge className={styles.svg} /> Récurrence
			</header>

			<main className={styles.main}>
				{/* Sélection du type de récurrence */}
				<Radio checked={!type} onClick={() => setType(undefined)}>
					Aucune
				</Radio>
				{/* <Radio
					checked={type === 'JOUR_DANS_LE_MOIS'}
					onClick={() => setType('JOUR_DANS_LE_MOIS')}
				>
					Tous les mois
				</Radio> */}
				<Radio
					checked={type === 'JOURS_DE_LA_SEMAINE'}
					onClick={() => setType('JOURS_DE_LA_SEMAINE')}
				>
					Toutes les semaines
				</Radio>

				{/* Sélection du jour du mois */}
				{type === 'JOUR_DANS_LE_MOIS' && (
					<div className={styles.block}>
						<p className={styles.blockTitle}>Vous recevrez votre commande</p>
						le{' '}
						<input
							type="number"
							value={dayOfMonth || ''}
							className={styles.dayOfMonth}
							onChange={(e) => {
								if (!e.target.value) setDayOfMonth(undefined);
								else setDayOfMonth(Math.min(28, Math.max(1, +e.target.value)));
							}}
						/>{' '}
						de chaque mois
					</div>
				)}

				{/* Sélection des jours de la semaine */}
				{type === 'JOURS_DE_LA_SEMAINE' && (
					<div className={styles.block}>
						<p className={styles.blockTitle}>Vous recevrez votre commande</p>
						<div className={styles.weekDays}>
							{joursDeLaSemaine.map((day, i) => (
								<CheckboxRecurrence
									key={i}
									checked={daysOfWeek.includes(day)}
									onClick={() =>
										setDaysOfWeek(
											daysOfWeek.includes(day)
												? daysOfWeek.filter((d) => d !== day)
												: [...daysOfWeek, day],
										)
									}
								>
									{day.charAt(0)}
								</CheckboxRecurrence>
							))}
						</div>
						<span>chaque semaine</span>
					</div>
				)}

				{/* Valider */}
				<div className={styles.buttonWrapper}>
					<Button
						onClick={() => confirmAndClose()}
						disabled={
							(type === 'JOUR_DANS_LE_MOIS' && !dayOfMonth) ||
							(type === 'JOURS_DE_LA_SEMAINE' && daysOfWeek.length === 0)
						}
					>
						Valider
					</Button>
				</div>
			</main>
		</PositionedPopin>
	);
}

export default PopinRecurrence;
