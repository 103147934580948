import React, { useState } from 'react';
import styles from './BtnFavori.module.scss';
import { ReactComponent as SVG } from './coeur-mc-gestion.svg';

interface Props {
	title: string;
	checked: boolean;
	onClick: Function;
	className?: string;
}

function BtnFavori({ checked, onClick, className, title }: Props) {
	const [hasBeenClicked, setClicked] = useState(false);

	function handleClick() {
		setClicked(!checked);
		onClick();
	}

	return (
		<button
			type="button"
			title={title}
			onClick={handleClick}
			className={[
				styles.wrapper,
				className,
				checked && styles.checked,
				hasBeenClicked && styles.animation,
			]
				.filter(Boolean)
				.join(' ')}
		>
			<SVG className={styles.picto} />
		</button>
	);
}

export default BtnFavori;
